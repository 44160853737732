import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import HomeIcon from "@mui/icons-material/Home";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";

import "../fake_result.css";
import { Diagram } from "./diagram/diagram";

export const Results = ({ questions }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [donwloadURL, setDownloadURL] = useState("");
  const [predictionData, setPredictionData] = useState({
    analytics: null,
    automation: null,
    computerAided: null,
    integration: null,
    management: null,
    productService: null,
    realTime: null,
    resource: null,
    supplyChain: null,
  });
  const timer = React.useRef();

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };
  useEffect(() => {
    const objectArray = {};
    var todayDate = new Date().toISOString().slice(0, 10);
    objectArray["date"] = todayDate;
    questions.forEach((question) => {
      question.categoryQuestions.forEach((answer) => {
        if (answer.id === "q0") {
          objectArray[answer.id] = answer.questionAnswer;
        } else {
          objectArray[answer.id] = parseFloat(answer.questionAnswer);
        }
      });
    });
    setSuccess(false);
    setLoading(true);

    fetch("https://digitalroadmaps.eu/api/generateFileURL", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(objectArray),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPredictionData({
          analytics: data.Analytics,
          automation: data.Automation,
          computerAided: data.Computer_aided,
          integration: data.Integration,
          management: data.Management,
          productService: data.Product_service,
          realTime: data.Real_time,
          resource: data.Resource,
          supplyChain: data.Supply_chain,
        });
        if (data.url) {
          setSuccess(true);
          setLoading(false);
          setDownloadURL(`https://digitalroadmaps.eu/${data.url}`);
        }
      })
      .catch(() => {
        setSuccess(false);
        setLoading(false);
      });
  }, []);

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
    }
  };

  return (
    <div className="result-container">
      <Link to={`/`} style={{ alignSelf: "start", margin: 17 }}>
        <HomeIcon sx={{ fontSize: 40, color: "grey" }} />
      </Link>
      <div className="diagram-container">
        <div className="result-text">Results</div>
        <p className="info-text">
          We have prepared your Digitalization Roadmap based on the data you
          have provided! Click below to download your Digitalization roadmap.
        </p>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              variant="contained"
              sx={buttonSx}
              disabled={loading}
              onClick={handleButtonClick}
              href={donwloadURL}
            >
              Download PDF
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Box>
      </div>

      <div className="divider" />

      <Diagram predictionData={predictionData} />
    </div>
  );
};
