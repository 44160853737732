import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

import "./question.css";

export const Question = ({
  categoryQuestion,
  saveQuestionAnswer,
  questionIndex,
  prevAnswer,
}) => {
  const contrainedQuestions = (questionAnswers, realAnswer) => {
    return questionAnswers.map((question) => {
      return (
        <FormControlLabel
          key={question + question.value}
          value={question.value}
          control={<Radio />}
          label={question.text}
          checked={Number(realAnswer) === question.value ? true : undefined}
          onChange={(e) => saveQuestionAnswer(e.target.value, questionIndex)}
        />
      );
    });
  };

  const freeFormQuestion = (answer) => {
    return (
      <TextField
        id="outlined-multiline-flexible"
        label="Answer"
        multiline
        maxRows={4}
        value={answer}
        onChange={(e) => {
          if (questionIndex === 0) {
            saveQuestionAnswer(e.target.value, questionIndex);
          } else {
            saveQuestionAnswer(
              e.target.value.replace(/\D/g, ""),
              questionIndex
            );
          }
        }}
      />
    );
  };

  const nonYearFreeFormQuestion = (answer) => {
    return (
      <TextField
        id="outlined-multiline-flexible"
        label="Answer"
        multiline
        maxRows={4}
        value={answer}
        onChange={(e) => {
          if (questionIndex === 0) {
            saveQuestionAnswer(e.target.value, questionIndex);
          } else {
            saveQuestionAnswer(
              e.target.value.replace(/\D/g, ""),
              questionIndex
            );
          }
        }}
      />
    );
  };

  const dropdownQuestion = (questionAnswers, realAnswer) => {
    return (
      <Select
        key={realAnswer}
        value={realAnswer}
        onChange={(e) => saveQuestionAnswer(e.target.value, questionIndex)}
        autoWidth
        style={{ minWidth: 150 }}
      >
        {questionAnswers.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const selectQuestion = (questionAnswers, realAnswer) => {
    return (
      <Select
        key={realAnswer}
        value={realAnswer}
        onChange={(e) => saveQuestionAnswer(e.target.value, questionIndex)}
        autoWidth
        style={{ minWidth: 150 }}
      >
        {questionAnswers.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    );
  };

  const questionFormatHandler = (questionType) => {
    switch (questionType) {
      case "freeForm":
        return freeFormQuestion(categoryQuestion.questionAnswer);

      case "nonYearFreeForm":
        return nonYearFreeFormQuestion(categoryQuestion.questionAnswer);

      case "constrained":
        return contrainedQuestions(
          categoryQuestion.answers,
          categoryQuestion.questionAnswer
        );

      case "dropdown":
        return dropdownQuestion(
          categoryQuestion.answers,
          categoryQuestion.questionAnswer
        );

      case "select":
        return selectQuestion(
          categoryQuestion.answers,
          categoryQuestion.questionAnswer
        );

      default:
        break;
    }
  };

  const displayQuestionHandler = (questionType) => {
    if (
      questionType === "freeForm" &&
      prevAnswer.questionAnswer !== "4" &&
      prevAnswer.questionAnswer !== "5"
    ) {
      return null;
    }

    return (
      <FormControl className="container" style={{ marginBottom: 20 }}>
        <FormLabel style={{ fontSize: 20 }}>
          {categoryQuestion.question}
        </FormLabel>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          {questionFormatHandler(questionType)}
        </RadioGroup>
      </FormControl>
    );
  };

  return displayQuestionHandler(categoryQuestion.questionType);
};
