import React, { useRef } from "react";
import { Link } from "react-router-dom";
import ArrowDown from "@mui/icons-material/ArrowDropDownTwoTone";
import StartIcon from "@mui/icons-material/Start";
import Button from "@mui/material/Button";
import LandingPageImage from "./landing-page.jpg";
import GifBackground from "./start-now.gif";
import Diagram1 from "./diagram1.jpg";
import Diagram2 from "./diagram2.jpg";
import Diagram3 from "./diagram3.jpg";
import Diagram4 from "./diagram4.jpg";
import EuLogo from "./eu.png";

import "./landingPage.css";
import "./stars.css";

export const LandingPage = () => {
  const whoWeAreRef = useRef(null);
  const whatYouGetRef = useRef(null);
  const whyNecessaryRef = useRef(null);
  const howWeDoItRef = useRef(null);
  const homewRef = useRef(null);

  const executeScrollWhoWeAre = () => whoWeAreRef.current.scrollIntoView();
  const executeScrollWhatYouGet = () => whatYouGetRef.current.scrollIntoView();
  const executeScrollWhyNecessary = () =>
    whyNecessaryRef.current.scrollIntoView();
  const executeScrollHowWeDoIt = () => howWeDoItRef.current.scrollIntoView();
  const executeScrollHome = () => homewRef.current.scrollIntoView();

  return (
    <div ref={homewRef} className="landing-page-container">
      <div
        style={{
          width: "60%",
          alignSelf: "center",
          margin: "10% 13%",
        }}
      >
        <div className="project-name_main">Machine learning-enabled</div>
        <div className="project-name_second">digitalization roadmaps</div>
        <div className="description">
          Benchmark you digitalization readiness against other manufacturing
          companies. Get individualized, machine learning-enabled digitalization
          roadmap.
        </div>
        <Link to={"/quiz"} className="quiz-link">
          <StartIcon sx={{ fontSize: 40, marginRight: 1 }} />
          Try it out now
        </Link>
      </div>

      <div className="divider" />

      <div className="links-container">
        <p onClick={executeScrollWhoWeAre} className="links-text">
          WHO WE ARE
        </p>
        <p onClick={executeScrollWhatYouGet} className="links-text">
          WHAT YOU GET
        </p>
        <p onClick={executeScrollWhyNecessary} className="links-text">
          WHY IT'S NECESSARY
        </p>
        <p onClick={executeScrollHowWeDoIt} className="links-text">
          HOW WE DO IT
        </p>
        <Link to={"/quiz"} className="small-quiz-link">
          TRY NOW
        </Link>
        {/* <Link to={"/results"} className="small-quiz-link">
          FAKE RESULTS
        </Link> */}
      </div>

      <div className="divider" />

      <div
        className="ready-container"
        style={{
          backgroundImage: `url(${GifBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="start-now-container">
          <p className="start-now-container_text">DR</p>

          <div className="info-container" style={{ textAlign: "end" }}>
            <p className="ready-text">
              Ready to benchmark your digitalization performance?
            </p>
            <Link to={"/quiz"} className="ready-quiz-link">
              TRY NOW
            </Link>
          </div>
        </div>
      </div>

      <div className="divider" ref={whoWeAreRef} />

      <div
        className="who-we-are"
        style={{
          backgroundImage: `url(${LandingPageImage})`,
        }}
      >
        <div className="section-container">
          <p className="section-name-bold">WHO</p>
          <p className="section-name">WE ARE</p>
        </div>
        <div className="info-container">
          <p className="info">
            We are the data-centered digitalization roadmapping team from Kaunas
            University of Technology. Expert-based digitalization audits are
            slow, expensive, and could be biased. Company-to-company
            digitalization benchmarking is free, fast, and allows comparing your
            current digitalization situation with what is happening in the
            field.
          </p>
          <p className="info">
            Digitalization roadmapping tool was supported by European Regional
            Development Fund (No 13.1.1-LMT-K-718-05-0026) under grant agreement
            with the Research Council of Lithuania (LMTLT). Funded as European
            Union's measure in response to Cov-19 pandemic.
          </p>
        </div>
      </div>

      <div className="divider" ref={whatYouGetRef} />

      <div
        className="section-container"
        style={{
          alignSelf: "center",
          marginTop: "100px",
          flexFlow: "unset",
          padding: "unset",
        }}
      >
        <p className="section-name-bold" style={{ marginRight: 20 }}>
          WHAT
        </p>
        <p className="section-name">YOU GET</p>
      </div>

      <div className="benefits-container" style={{ marginBottom: 50 }}>
        <div className="component">
          <img
            style={{ opacity: 1, height: 300 }}
            src={Diagram1}
            alt="Diagram 1"
          />
          <div className="text">
            Benchmark you digitalizaiton capabilities with average company
          </div>
        </div>
        <div className="component">
          <img
            style={{ opacity: 1, height: 300 }}
            src={Diagram2}
            alt="Diagram 2"
          />
          <div className="text">
            Understand you “soft” and “hard” capabilities balance
          </div>
        </div>
        <div className="component">
          <img
            style={{ opacity: 1, height: 300 }}
            src={Diagram3}
            alt="Diagram 3"
          />
          <div className="text">
            Benchmark you digitalization practices with top 10% companies that
            maximize their revenue enhancement from digitalization
          </div>
        </div>
      </div>
      <div className="benefits-container" style={{ marginTop: 0 }}>
        <div className="component">
          <img
            style={{ opacity: 1, height: 400 }}
            src={Diagram4}
            alt="Diagram 4"
          />
        </div>
      </div>

      <div className="divider" />

      <div
        className="why-necessary"
        style={{
          backgroundImage: `url(${LandingPageImage})`,
          backgroundPosition: "center",
        }}
        ref={whyNecessaryRef}
      >
        <div className="info-container" style={{ textAlign: "end" }}>
          <p className="info">
            Digital technologies offer the immense transformative potential to
            rediscover operating systems’ processes, supply chain management
            practices and infuse products with intelligent features.
            Front-running companies can mobilize the affordances of digital
            technologies to shape the competition.
          </p>
          <p className="info">
            Other companies should keep digitalization’s pace at least similar
            to their competitors. Our increasing database allows benchmarking
            your digitalization pace and discovers the level of your digital
            transformation capabilities in relation to leading and average
            companies.
          </p>
        </div>
        <div
          className="section-container"
          style={{ alignItems: "unset", padding: "0 0 0 100px" }}
        >
          <p className="section-name-bold">WHY</p>
          <p className="section-name" style={{ fontSize: 60 }}>
            IT'S NECESSARY
          </p>
        </div>
      </div>

      <div className="divider" ref={howWeDoItRef} />

      <div
        className="section-container"
        style={{
          alignSelf: "center",
          marginTop: "100px",
          flexFlow: "unset",
          padding: "0 0 75px 0",
        }}
      >
        <p className="section-name-bold" style={{ marginRight: 20 }}>
          HOW
        </p>
        <p className="section-name">WE DO IT</p>
      </div>

      <div className="how">
        <p
          className="info"
          style={{ maxWidth: 1200, fontSize: 25, marginBottom: 75 }}
        >
          Our model relies on the evidence-based finding that digital
          technologies come hand-in-hand with organizational transformation
          management and resource accumulation capabilities.{" "}
        </p>
        <p className="how-text">Transformation management capabilities</p>
        <ArrowDown className="how-svg" />
        <p className="how-text">Resource accumulation and availability</p>
        <ArrowDown className="how-svg" />
        <p className="how-text">Operating system digitalization</p>
        <ArrowDown className="how-svg" />
        <p className="how-text">Supply chain digitalization</p>
        <ArrowDown className="how-svg" />
        <p className="how-text">Digital servitization</p>
        <ArrowDown className="how-svg" />
        <p className="how-text" style={{ color: "crimson", fontWeight: 800 }}>
          Time
        </p>
      </div>

      <div
        className="divider"
        style={{ boxShadow: "black 0px 7px 13px 0px" }}
      />

      <div
        className="section-container"
        style={{
          alignSelf: "center",
          marginTop: "200px",
          flexFlow: "unset",
          padding: "0 0 75px 0",
        }}
      >
        <p className="section-name-bold" style={{ marginRight: 20 }}>
          TRY
        </p>
        <p className="section-name">IT NOW</p>
      </div>

      <p
        className="info"
        style={{
          maxWidth: 1200,
          fontSize: 25,
          marginBottom: 75,
          alignSelf: "center",
        }}
      >
        You fill out the questionnaire on your company’s digitalization
        practices. The questionnaire will take up to 20 minutes to fill. The
        more precise data you provide – the more accurate the results are. Our
        proprietary algorithms anonymously and securely process the data you
        enter. The algorithmic engine generates a tailored digitalization
        roadmap that allows you to benchmark your digital transformation
        performance.
      </p>

      <Button variant="contained" className="link-button">
        <Link to={"/quiz"} className="link-button_text">
          START
        </Link>
      </Button>

      <div
        className="divider"
        style={{ boxShadow: "black 0px -7px 13px 0px" }}
      />

      <div className="who-we-are" style={{ padding: "4% 20%" }}>
        <Link
          className="section-container"
          style={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
          to={"https://2014.esinvesticijos.lt/lt/"}
          target="_blank"
        >
          <img
            style={{ opacity: 1, width: 300, height: 200, marginRight: 20 }}
            src={EuLogo}
            alt="EU Logo"
          />
          <div>
            <p style={{ width: 300, marginRight: 20 }}>
              Kuriame Lietuvos ateitį 2014-2020 metų Europos Sąjungos fondų
              investicijų veiksmų programa
            </p>
            <p style={{ width: 300 }}>
              Europos socialinis fondas Sanglaudos fondas Europos regioninės
              plėtros fondas
            </p>
          </div>
        </Link>
        <div
          className="info-container"
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <p className="info">
            This project has received funding from European Regional Development
            Fund (project No 13.1.1-LMT-K-718-05-0026) under grant agreement
            with the Research Council of Lithuania (LMTLT). Funded as European
            Union's measure in response to Cov-19 pandemic.
          </p>
        </div>
      </div>

      <div className="footer-container">
        <p className="footer-text" style={{ cursor: "pointer" }}>
          CONTACT
        </p>
        <p
          className="footer-text"
          style={{ cursor: "pointer", marginRight: 500, marginLeft: 20 }}
        >
          PRIVACY
        </p>
        <p
          className="footer-text"
          onClick={executeScrollHome}
          style={{ cursor: "pointer" }}
        >
          © DIGITAL ROADMAPS
        </p>
      </div>
    </div>
  );
};
