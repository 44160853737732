const naceList = [
  {
    text: "C.10 Manufacture of food products",
    value: 10,
  },
  {
    text: "C.11 Manufacture of beverages",
    value: 11,
  },
  {
    text: "C.12 Manufacture of tobacco products",
    value: 12,
  },
  {
    text: "C.13 Manufacture of textiles",
    value: 13,
  },
  {
    text: "C.14 Manufacture of wearing apparel",
    value: 14,
  },
  {
    text: "C.15 Manufacture of leather and related products",
    value: 15,
  },
  {
    text: "C.16 Manufacture of wood and of products of wood and cork, except furniture; manufacture of articles of straw and plaiting materials",
    value: 16,
  },
  {
    text: "C.17 Manufacture of paper and paper products",
    value: 17,
  },
  {
    text: "C.18 Printing and reproduction of recorded media",
    value: 18,
  },
  {
    text: "C.19 Manufacture of coke and refined petroleum products",
    value: 19,
  },
  {
    text: "C.20 Manufacture of chemicals and chemical products",
    value: 20,
  },
  {
    text: "C.21 Manufacture of basic pharmaceutical products and pharmaceutical preparations",
    value: 21,
  },
  {
    text: "C.22 Manufacture of rubber and plastic products",
    value: 22,
  },
  {
    text: "C.23 Manufacture of other non-metallic mineral products",
    value: 23,
  },
  {
    text: "C.24 Manufacture of basic metals",
    value: 24,
  },
  {
    text: "C.25 Manufacture of fabricated metal products, except machinery and equipment",
    value: 25,
  },
  {
    text: "C.26 Manufacture of computer, electronic and optical products",
    value: 26,
  },
  {
    text: "C.27 Manufacture of electrical equipment",
    value: 27,
  },
  {
    text: "C.28 Manufacture of machinery and equipment n.e.c.",
    value: 28,
  },
  {
    text: "C.29 Manufacture of motor vehicles, trailers and semi-trailers",
    value: 29,
  },
  {
    text: "C.30 Manufacture of other transport equipment",
    value: 30,
  },
  {
    text: "C.31 Manufacture of furniture",
    value: 31,
  },
  {
    text: "C.32 Other manufacturing",
    value: 32,
  },
  {
    text: "C.33 Repair and installation of machinery and equipment",
    value: 33,
  },
];

const countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antigua Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia Herzegovina",
  "Botswana",
  "Brazil",
  "British Virgin Islands",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Cape Verde",
  "Cayman Islands",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Congo",
  "Cook Islands",
  "Costa Rica",
  "Cote D Ivoire",
  "Croatia",
  "Cruise Ship",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "French Polynesia",
  "French West Indies",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guam",
  "Guatemala",
  "Guernsey",
  "Guinea",
  "Guinea Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland",
  "Isle of Man",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jersey",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kuwait",
  "Kyrgyz Republic",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Namibia",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russia",
  "Rwanda",
  "Saint Pierre Miquelon",
  "Samoa",
  "San Marino",
  "Satellite",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "South Africa",
  "South Korea",
  "Spain",
  "Sri Lanka",
  "St Kitts Nevis",
  "St Lucia",
  "St Vincent",
  "St. Lucia",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor L'Este",
  "Togo",
  "Tonga",
  "Trinidad Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks Caicos",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (US)",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

const constrained5 = [
  {
    text: "Strongly disagree",
    value: 1,
  },
  {
    text: "Disagree",
    value: 2,
  },
  {
    text: "Neither agree or disagree",
    value: 3,
  },
  {
    text: "Agree",
    value: 4,
  },
  {
    text: "Strongly agree",
    value: 5,
  },
];

const constrained6 = [
  {
    text: "Strongly disagree",
    value: 1,
  },
  {
    text: "Disagree",
    value: 2,
  },
  {
    text: "Neither agree or disagree",
    value: 3,
  },
  {
    text: "Agree",
    value: 4,
  },
  {
    text: "Strongly agree",
    value: 5,
  },
  {
    text: "Not aplicable for our company",
    value: 6,
  },
];

const constrained7 = [
  {
    text: "Very low",
    value: 1,
  },
  {
    text: "Low",
    value: 2,
  },
  {
    text: "Below average",
    value: 3,
  },
  {
    text: "Average",
    value: 4,
  },
  {
    text: "Above average",
    value: 5,
  },
  {
    text: "High",
    value: 6,
  },
  {
    text: "Very high",
    value: 7,
  },
];

const constrained6New = [
  {
    text: "A lot of worse",
    value: 1,
  },
  {
    text: "Worse",
    value: 2,
  },
  {
    text: "Equal",
    value: 3,
  },
  {
    text: "Better",
    value: 4,
  },
  {
    text: "A lot of better",
    value: 5,
  },
];

const constrainedPercent = [
  {
    text: "negative",
    value: 1,
  },
  {
    text: "< 0 up to 2%",
    value: 2,
  },
  {
    text: "< 2 up to 5%",
    value: 3,
  },
  {
    text: "< 5 up to 10%",
    value: 4,
  },
  {
    text: "> 10%",
    value: 5,
  },
];

export const data = [
  {
    categoryName: "Questionnaire",
    categoryQuestions: [
      {
        id: "q0",
        question:
          "Select in which country the manufacturing site you are referring is located:",
        questionType: "dropdown",
        answers: countries,
        questionAnswer: undefined,
      },
      {
        id: "q1",
        question:
          "What is your industry representing the main line of products according to NACE:",
        questionType: "select",
        answers: naceList,
        questionAnswer: undefined,
      },
      {
        id: "q4",
        question: "Regarding your main line of products, is your factory:",
        questionType: "constrained",
        answers: [
          {
            text: "Single unit production",
            value: 1,
          },
          {
            text: "Small or medium batch/lot",
            value: 2,
          },
          {
            text: "Parge batch/lot",
            value: 3,
          },
          {
            text: "Process/ continuous production",
            value: 4,
          },
        ],
        questionAnswer: undefined,
      },
      {
        id: "q5",
        question: "Regarding your main line of products, is your factory:",
        questionType: "constrained",
        answers: [
          {
            text: "Producer of finished goods for companies or final customers",
            value: 1,
          },
          {
            text: "Parts/components supplier",
            value: 2,
          },
          {
            text: "Contract manufacturer (such as turning, coating, welding, grinding)",
            value: 3,
          },
        ],
        questionAnswer: undefined,
      },
      {
        id: "q6",
        question:
          "Which of the following characteristics best describes your main product or line of products:",
        questionType: "constrained",
        answers: [
          {
            text: "According to customers’ specification",
            value: 1,
          },
          {
            text: "As a standardized basic program incorporating customer-specific options",
            value: 2,
          },
          {
            text: "For a standard program from which the customer can choose options",
            value: 3,
          },
          {
            text: "Does not exist in this factory",
            value: 4,
          },
        ],
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Digitalization readiness assessment competency",
    categoryQuestions: [
      {
        id: "q7_1",
        question:
          "We have the ability to identify and assess the opportunities to digitalize our business and manufacturing processes",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
      {
        id: "q7_2",
        question:
          "We have the ability to identify talent and knowledge gaps for digitalization",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
      {
        id: "q7_3",
        question:
          "We have the ability to assess the integrability of new didital technologies with our present operations and information technologies",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Digitalization capability building competency",
    categoryQuestions: [
      {
        id: "q8_1",
        question:
          "The management team prepared and updates company’s digitalization strategy/plan or roadmap",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
      {
        id: "q8_1_1",
        question: "year established/ start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q8_2",
        question:
          "We have clear and measurable digitalization goals and priorities",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
      {
        id: "q8_2_1",
        question: "year established/ start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q8_3",
        question:
          "We have contingency plans for risks or threats to our digital transformation projects",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Change management competency",
    categoryQuestions: [
      {
        id: "q9_1",
        question:
          "The management team is actively involved with the changes introduced by digitalization",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
      {
        id: "q9_2",
        question:
          "There is clear communication on objectives and consistent reporting of digitalization progress",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
      {
        id: "q9_3",
        question:
          "We are aware of the effects that the introduction of new digital technologies will have on the company",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "External support sensing and acquisition",
    categoryQuestions: [
      {
        id: "q10_1",
        question:
          "We constantly seek existing government support (e.g., external project funding) for digitalization",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
      {
        id: "q10_2",
        question:
          "We seek professional digitalization training support from technology providers",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
      {
        id: "q10_3",
        question:
          "We seek external support to help with the digitalization planning and readiness assessment",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Financial capital availability",
    categoryQuestions: [
      {
        id: "q11_1",
        question:
          "We have the necessary capital to fund the initial cost of the digital technology acquisition (e.g., purchasing costs of hardware and software)",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
      {
        id: "q11_2",
        question:
          "We have the necessary capital to fund digitalization training for all relevant employees",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
      {
        id: "q11_3",
        question:
          "We have the necessary capital to fund employing new digitalization talents or external digitalization experts/consultants",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Information and digital technology skills and expertise",
    categoryQuestions: [
      {
        id: "q12_1",
        question:
          "We have the necessary skills for effective digitalization project management",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
      {
        id: "q12_2",
        question:
          "We have the necessary knowledge and skills to seamlessly integrate new digital technologies with existing information and operations technologies",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
      {
        id: "q12_3",
        question:
          "We have the necessary skills (e.g., data science or software engineering) to use and manage our digital technologies effectively",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Supportive organizational culture",
    categoryQuestions: [
      {
        id: "q13_1",
        question:
          "Our employees are open to changes introduced by digitalization",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
      {
        id: "q13_2",
        question:
          "Our employees can collaborate functionally across digital transformation initiatives",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
      {
        id: "q13_3",
        question:
          "We empower our employees to be involved in the digitalization decision and management processes ",
        questionType: "constrained",
        answers: constrained5,
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Intelligent products (ICT add-ons to products)",
    categoryQuestions: [
      {
        id: "q15_1",
        question:
          "Our products are embeddesd with RFIDs, sensors, or microprocessors",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q15_1_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q15_2",
        question: "Our products have wireless or Bluetooth connection",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q15_2_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q15_3",
        question:
          "Our company is able to capture data about condition and usage of the products",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q15_3_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Digital customer support",
    categoryQuestions: [
      {
        id: "q16_1",
        question: "We use CRM software to track sales process progress",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q16_1_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q16_2",
        question: "We sell our products/services in webshop",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q16_2_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q16_3",
        question:
          "Customers can customize/design products/orders in our website",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q16_3_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Customer support services",
    categoryQuestions: [
      {
        id: "q17_1",
        question:
          "We provide our customers with prototypes design and testing services",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q17_2",
        question:
          "We provide our customers with consulting services (e.g., technical consulting, process-oriented consulting)",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q17_3",
        question:
          "We use service business models with performance-based pricing depending on use, availability or customer output quantity",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q17_3_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "ERP supported manufacturing",
    categoryQuestions: [
      {
        id: "q18_1",
        question:
          "We use software (e.g., ERP, MES) for production planning and control",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q18_1_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q18_2",
        question:
          "We can identify the order delivery progress of each order batch in our software (e.g., ERP, MES) system",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q18_2_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q18_3",
        question:
          "Our sales, manufacturing planning, warehouse and accounting processes are integrated using software (e.g., ERP, MES)",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q18_3_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Industrial automation (robots)",
    categoryQuestions: [
      {
        id: "q19_1",
        question:
          "We use industrial robots for manufacturing processes (e.g. welding, painting, cutting)",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q19_1_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q19_2",
        question: "We use collaborating robots (Cobots)",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q19_2_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q19_3",
        question:
          "We automate routines activities using robotic process automation (RPA) solutions or manufacturing aplications",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q19_3_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Computer-aided design and manufacturing",
    categoryQuestions: [
      {
        id: "q20_1",
        question:
          "We use computer-aided design solutions (e.g., CAD software) across manufacturing operations",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q20_1_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q20_2",
        question:
          "We use programable CNC manufacturing equipment/lines in our production",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q20_2_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q20_3",
        question:
          "We use additive manufacturing (e.g., 3D printers) for prototyping new products, parts, or tools",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q20_3_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Digitization of production documentation/ paperless",
    categoryQuestions: [
      {
        id: "q21_1",
        question:
          "We use electronic interactive procedures and instructions on the shop floor",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q21_1_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q21_2",
        question:
          "The production workers receive tasks and report performance using computers or tablets",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q21_2_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q21_3",
        question:
          "No paper-based production order documentation is moving along production operations with a particular batch or order",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q21_3_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Real-time control of inventory",
    categoryQuestions: [
      {
        id: "q22_1",
        question:
          "We have precise, real-time information on our raw material inventory levels",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q22_1_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q22_2",
        question:
          "We use solutions for generation of automatic orders for stockouts of materials or components",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q22_2_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q22_3",
        question:
          "We have precise, real-time information on our finished goods inventory levels",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q22_3_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Real-time control of manufacturing",
    categoryQuestions: [
      {
        id: "q23_1",
        question:
          "We automatically collect data on performance of each work center or production lines with minutes or hours precision",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q23_1_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q23_2",
        question:
          "The work-in-process inventory is equipped with barcodes, RFIDs allowing automatic tracking of stages of production",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q23_2_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q23_3",
        question:
          "We automatically collect data on downtime, setup times, run times of the manufacturing equipment/lines with minutes precision",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q23_3_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Machine learning/AI solutions in manufacturing",
    categoryQuestions: [
      {
        id: "q24_1",
        question:
          "We use machine learning/AI predictions for product demand forecasting",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q24_1_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q24_2",
        question:
          "We use machine learning/AI for automatic control of quality through image recognition or other means",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q24_2_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q24_3",
        question:
          "We use machine learning/AI for predictive maintenance through real-time anomaly detection",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q24_3_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Simulation",
    categoryQuestions: [
      {
        id: "q25_1",
        question:
          "We use simulations to identify causes of technical problems involved in the production process and develop necessary solutions",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q25_1_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q25_2",
        question:
          "We use process mining for identification of process botlenects and propose imrovements",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q25_2_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q25_3",
        question:
          "We use simulations to predict plant performance and test the effectiveness of the production schedule",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q25_3_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Augmented reality solutions",
    categoryQuestions: [
      {
        id: "q26_1",
        question:
          "We use augmented/virtual reality for employee training purposes",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q26_1_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q26_2",
        question:
          "We use augmented reality for asset identification across various functions (e.g., warehousing)",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q26_2_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q26_3",
        question:
          "We use augmented reality to streamline maintenance management operations",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q26_3_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Fully-automated/smart manufacturing",
    categoryQuestions: [
      {
        id: "q27_1",
        question:
          "All our manufacturing processes are integrated into continuous automated production lines",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q27_1_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q27_2",
        question:
          "Real-time data generated across production processes are used for autonomous production control",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q27_2_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q27_3",
        question:
          "We autonomously analyze the historical data and patterns collected across production processes to make informed decisions",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q27_3_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Digital supply chain integration",
    categoryQuestions: [
      {
        id: "q28_1",
        question:
          "We commonly share information about production plans, inventory status, or delivery status with our key suppliers",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q28_1_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q28_2",
        question:
          "We have developed collaborative demand forecasting, risk management, or production planning approaches with our key suppliers",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q28_2_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
      {
        id: "q28_3",
        question:
          "We have system coupling with our key suppliers (e.g., continuous replenishment or vendor managed inventory)",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q28_3_1",
        question: "Year established/start of use",
        questionType: "freeForm",
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Business partner digitalization readiness",
    categoryQuestions: [
      {
        id: "q29_1",
        question:
          "Our business partners are willing to use automatic data capture systems (e.g., RFID, barcode, etc.)",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q29_2",
        question:
          "We have developed collaborative demand forecasting, risk management, or production planning approaches with our key suppliers",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q29_3",
        question:
          "We have system coupling with our key suppliers (e.g., continuous replenishment or vendor managed inventory)",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Digital transformation collaboration and partnership",
    categoryQuestions: [
      {
        id: "q30_1",
        question:
          "We develop partnerships with universities and research centers to facilitate the digitalization processes",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q30_2",
        question:
          "We develop partnerships with our business partners to facilitate the digitalization processes",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
      {
        id: "q30_3",
        question:
          "We develop partnerships with technology vendors to receive technologies, training, and support tailored to our specific needs",
        questionType: "constrained",
        answers: constrained6,
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName:
      "Evaluate general digitalization-related investments and revenue enhancement over last 3 years",
    categoryQuestions: [
      {
        id: "q31_1",
        question:
          "Indicate the level of cumulative investments into digitalization for the last three years",
        questionType: "constrained",
        answers: constrained7,
        questionAnswer: undefined,
      },
      {
        id: "q31_2",
        question:
          "valuate the direct revenue enhancement from digitalization for the last three years (i.e., growing revenue from digital services, online sales)",
        questionType: "constrained",
        answers: constrained7,
        questionAnswer: undefined,
      },
      {
        id: "q31_3",
        question:
          "Evaluate the indirect revenue enhancement from digitalization (i.e., improving competitive advantage or quality of customer relationship) which leads to increase of revenues for the last three years",
        questionType: "constrained",
        answers: constrained7,
        questionAnswer: undefined,
      },
      {
        id: "q31_4",
        question: "Evaluate cost savings from digitalization ",
        questionType: "constrained",
        answers: constrained7,
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName:
      "Indicate how well your factory performed compared to its competitors within your industry over the last 3 years along the following performance dimensions",
    categoryQuestions: [
      {
        id: "q32_1",
        question: "Sales growth",
        questionType: "constrained",
        answers: constrained6New,
        questionAnswer: undefined,
      },
      {
        id: "q32_2",
        question: "Market share",
        questionType: "constrained",
        answers: constrained6New,
        questionAnswer: undefined,
      },
      {
        id: "q32_3",
        question: "Overall competitive position",
        questionType: "constrained",
        answers: constrained6New,
        questionAnswer: undefined,
      },
      {
        id: "q32_4",
        question: "Harmful emission prevention",
        questionType: "constrained",
        answers: constrained6New,
        questionAnswer: undefined,
      },
      {
        id: "q32_5",
        question: "Industrial waste prevention",
        questionType: "constrained",
        answers: constrained6New,
        questionAnswer: undefined,
      },
      {
        id: "q32_6",
        question: "Integrating (using) green resources",
        questionType: "constrained",
        answers: constrained6New,
        questionAnswer: undefined,
      },
    ],
  },

  {
    categoryName: "Factory characterization",
    categoryQuestions: [
      {
        id: "q33_1",
        question: "Annual turnover/sales last year in €, (e.g., 300500)",
        questionType: "nonYearFreeForm",
        questionAnswer: undefined,
      },
      {
        id: "q33_2",
        question: "Return on sales (before tax) last year",
        questionType: "constrained",
        answers: constrainedPercent,
        questionAnswer: undefined,
      },
      {
        id: "q33_3",
        question: " Extent of exports as % of revenues (e.g., 20)",
        questionType: "nonYearFreeForm",
        questionAnswer: undefined,
      },
      {
        id: "q33_4",
        question: "Number of employees in 2021",
        questionType: "nonYearFreeForm",
        questionAnswer: undefined,
      },
      {
        id: "q33_5",
        question: "Number of IT specialists in your site",
        questionType: "nonYearFreeForm",
        questionAnswer: undefined,
      },
      {
        id: "q33_6",
        question:
          "Number of math/statistics/data science specialists on site, ",
        questionType: "nonYearFreeForm",
        questionAnswer: undefined,
      },
      {
        id: "q33_7",
        question: "Year of the factory was established",
        questionType: "nonYearFreeForm",
        questionAnswer: undefined,
      },
    ],
  },
];
