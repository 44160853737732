import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "./survey.css";
import { QuestionHandler, Progress } from "./components/index";
import { data } from "./data";
import { Results } from "./components/index";

export const Survey = () => {
  const [categoryIndex, setCategoryIndex] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [questions, setQuestions] = useState(data);
  const [isFinalQuestion, setIsFinalQuestion] = useState(false);
  const [isSurveyDone, setIsSurveyDone] = useState(false);
  const isPreviousAvailable = categoryIndex === 0 ? false : true;

  const nextQuestionHandler = () => {
    const newIndexValue = categoryIndex + 1;
    const newPercentageValue = (
      (newIndexValue / questions.length) *
      100
    ).toFixed(0);

    if (newIndexValue + 1 === questions.length) {
      setIsFinalQuestion(true);
    }

    if (newIndexValue !== questions.length) {
      setCategoryIndex(newIndexValue);
      setProgressPercentage(newPercentageValue);
    } else {
      setIsSurveyDone(true);
    }
  };

  const previousQuestionHandler = () => {
    const newIndexValue = categoryIndex - 1;
    const newPercentageValue = (
      (newIndexValue / questions.length) *
      100
    ).toFixed(0);

    setCategoryIndex(newIndexValue);
    setProgressPercentage(newPercentageValue);
  };

  const showPreviousButton = () => {
    if (isPreviousAvailable)
      return (
        <>
          <Button
            style={{ height: 40, fontWeight: 800, color: "darkgray" }}
            variant="text"
            onClick={() => previousQuestionHandler()}
          >
            <ArrowBackIcon sx={{ fontSize: 25, color: "darkgray" }} />
            Previous
          </Button>
        </>
      );
  };

  const saveQuestionAnswer = (answer, questionIndex) => {
    setQuestions((prevState) => {
      let newState = prevState;
      newState[categoryIndex].categoryQuestions[questionIndex].questionAnswer =
        answer;

      return [...newState];
    });
  };

  return (
    <>
      {isSurveyDone ? (
        <Results questions={questions} />
      ) : (
        <>
          <Progress progressValue={progressPercentage} />
          <div className="button-container">
            <Link to={`/`}>
              <HomeIcon sx={{ fontSize: 40, color: "grey" }} />
            </Link>
            {showPreviousButton()}
          </div>

          <QuestionHandler
            questions={questions[categoryIndex]}
            categoryIndex={categoryIndex}
            nextQuestion={nextQuestionHandler}
            saveQuestionAnswer={saveQuestionAnswer}
            isFinalQuestion={isFinalQuestion}
          />
        </>
      )}
    </>
  );
};
