export const managementData = [
  2.2778, 1.8889, 3.3333, 3.1111, 4.0, 2.8889, 3.1667, 2.1667, 4.3889, 2.7778,
  3.7778, 3.3889, 2.8333, 3.8889, 2.2222, 2.8889, 3.3889, 3.8333, 2.8333,
  3.7778, 3.6667, 2.4444, 3.5556, 3.2778, 2.3333, 3.1111, 2.5, 1.5556, 1.8333,
  3.3889, 2.3889, 2.2778, 1.3333, 2.7778, 2.1667, 1.6111, 2.7222, 3.3333,
  3.3889, 3.2778, 3.8889, 4.4444, 2.0556, 1.7778, 4.3333, 3.0556, 3.4444,
  3.2778, 3.1111, 2.9444, 3.6111, 3.6667, 2.4444, 3.4444, 1.1111, 2.5556,
  2.1667, 3.2778, 4.0556, 1.8889, 2.8333, 3.1111, 2.5556, 3.3333, 2.8889,
  3.3889, 3.8333, 2.0556, 3.5, 3.5556, 3.2222, 4.1111, 2.6111, 3.1111, 3.1667,
  2.5, 3.0, 3.6111, 2.7778, 2.7222, 3.2222, 2.1667, 3.5, 2.7778, 3.3333, 3.3889,
  2.6667, 2.3889, 2.3889, 2.1667, 3.1667, 3.6667, 3.7222, 3.1111, 3.4444,
  3.1667, 3.5556, 3.2778, 2.6111, 2.3333, 1.7222, 3.2778, 3.3889, 1.9444,
  3.3889, 3.1667, 2.8889, 3.3889, 3.1111, 2.9444, 2.5, 2.3889, 2.8333, 3.1667,
  3.0556, 2.9444, 2.9444, 3.5, 3.6111, 3.2778, 3.3889, 3.8333, 3.3333, 3.5556,
  3.3333, 2.8889, 2.2778, 3.3333, 2.8333, 3.7222, 3.1667, 1.4444, 2.7222,
  3.5556, 2.7778, 3.6111, 2.1667, 3.3889, 3.6111, 1.6111, 3.5556, 1.1667,
  4.1667, 3.2778, 1.2222, 2.0556, 3.0556, 3.0556, 2.5556, 2.8333, 3.3889,
  3.6667, 2.5556, 3.3889, 2.0, 2.7222, 3.1111, 1.8889, 3.2222, 3.4444, 3.2222,
  2.1667, 2.8889, 2.7778, 4.1667, 3.7222, 3.1111, 3.7222, 3.3889, 3.5556,
  2.8889, 4.2222, 3.5556, 2.7222, 3.2778, 4.9444, 3.2222, 4.0556, 2.1667,
  3.7222, 3.7778, 2.5556, 3.1111, 2.9444, 3.5556, 2.5556, 3.2222, 3.5, 4.0,
  3.6667, 3.0, 3.8889, 4.0556, 3.2778, 3.6111, 3.8889, 3.4444, 3.6111, 3.5556,
  3.8333, 3.6111, 3.3333, 2.9444, 1.1667, 2.4444, 1.8889, 2.7222, 3.5, 3.3889,
  3.9444, 3.5, 2.8333, 3.7222, 2.3333, 3.1111, 2.4444, 2.3333, 3.7222, 1.9444,
  3.2222, 4.5, 3.0, 3.2222, 3.9444, 3.5, 1.5, 3.0556, 3.6667, 1.9444, 3.6667,
  4.0, 2.5556, 3.7778, 3.7778, 2.2222, 3.6111, 4.0556, 3.9444, 2.2778, 4.0,
  3.8333, 3.0, 2.5556, 2.8889, 2.1667, 3.7778, 3.4444, 2.5, 3.0, 2.6111, 3.7222,
  2.1667, 1.7778, 3.2778, 3.1667, 3.5, 3.2222, 3.3889, 3.5, 1.6111, 1.2778,
  2.3333, 2.8333, 3.2222, 1.5, 3.9444, 4.1667, 3.0, 3.3333, 4.7778, 3.3889,
  3.4444, 3.0, 3.6667, 2.8333, 2.6111, 3.1111, 2.8889, 2.1667, 3.4444, 4.1667,
  1.9444, 2.2222, 3.5556, 3.1667, 3.1111, 3.8889, 4.1667, 3.8889, 4.0, 3.2222,
  2.6667, 4.2778, 3.5, 2.1111, 3.2222, 2.6667, 2.3333, 3.5, 3.7778, 3.7222,
  3.1667, 3.8333, 2.9444, 3.1667, 3.7778, 4.0, 1.2778, 1.5, 4.3889, 2.3889,
  3.8889, 3.5556, 3.8333, 3.5556, 3.1667, 3.8889, 4.0556, 3.3333, 2.1111,
  3.6111, 2.2222, 2.8333, 4.1111, 2.2778, 3.4444, 3.2778, 2.1667, 3.5556,
  2.2222, 1.5556, 2.5, 2.1111, 2.6111, 1.5556, 2.4444, 2.2778, 3.7222, 4.4444,
  1.8333, 3.1667, 2.3333, 2.2778, 3.6667, 2.2778, 3.6111, 2.1111, 2.2778,
  4.1667, 2.3333, 2.5, 3.0556, 2.8333, 3.8333, 3.7222, 3.0556, 1.4444, 3.7778,
  3.0, 3.8889, 3.3333, 3.8333, 3.7778, 1.8333, 2.2222, 3.3889, 3.7778, 3.1667,
  2.2778, 2.7222, 2.2222, 3.8889, 4.5, 4.4444, 3.3333, 3.4444, 2.0556, 3.7778,
  4.5, 1.9444, 2.6111, 1.5, 2.3333, 2.7778, 2.3333, 3.7222, 3.6111, 3.2222,
  2.0556, 3.1667, 3.6111, 2.4444, 2.6667, 3.0556, 3.9444, 4.0, 3.5556, 3.1667,
  4.6111, 2.8333, 1.4444, 2.0, 4.0556, 4.1111, 2.8889, 2.8333, 2.9444, 2.5556,
  3.1111, 3.0556, 1.5,
];

export const resourceData = [
  2.1667, 4.1667, 4.8333, 4.5, 4.5, 4.3333, 2.6667, 2.1667, 3.3333, 1.0, 2.6667,
  3.0, 2.0, 3.0, 3.1667, 2.5, 3.5, 3.8333, 2.6667, 3.0, 3.8333, 2.0, 3.6667,
  3.5, 2.3333, 2.3333, 2.3333, 1.8333, 2.0, 3.3333, 2.0, 3.0, 4.0, 2.6667,
  2.8333, 1.1667, 2.6667, 3.6667, 2.3333, 4.3333, 4.0, 2.3333, 2.5, 1.8333,
  3.3333, 3.8333, 3.3333, 4.1667, 3.3333, 3.3333, 4.0, 4.0, 2.8333, 4.0, 1.3333,
  3.5, 3.0, 2.6667, 4.3333, 2.8333, 2.0, 2.5, 2.5, 2.6667, 3.1667, 5.0, 3.0,
  2.0, 3.0, 4.5, 2.8333, 3.5, 2.8333, 2.3333, 2.8333, 3.1667, 2.5, 4.3333,
  2.3333, 3.0, 2.3333, 1.3333, 3.3333, 2.8333, 4.5, 3.8333, 2.6667, 2.3333,
  3.3333, 2.3333, 2.6667, 2.3333, 4.3333, 4.0, 4.0, 3.0, 2.8333, 4.3333, 3.0,
  3.6667, 1.5, 3.6667, 3.6667, 2.6667, 2.6667, 5.0, 3.1667, 4.6667, 3.3333, 3.0,
  3.3333, 4.0, 3.0, 4.0, 2.8333, 3.1667, 3.0, 4.0, 3.5, 4.3333, 3.8333, 4.6667,
  3.0, 4.0, 4.0, 2.3333, 2.3333, 4.6667, 2.3333, 3.0, 3.8333, 2.6667, 3.3333,
  3.0, 4.0, 3.5, 2.1667, 4.3333, 2.8333, 1.3333, 3.5, 1.0, 3.5, 4.0, 2.3333,
  3.3333, 3.5, 2.3333, 5.0, 3.8333, 4.0, 3.8333, 2.8333, 4.0, 1.6667, 3.1667,
  3.8333, 2.5, 3.1667, 3.8333, 3.6667, 1.6667, 3.1667, 2.3333, 3.6667, 4.1667,
  3.1667, 3.3333, 4.6667, 4.1667, 3.8333, 4.0, 4.0, 2.5, 4.3333, 4.1667, 4.0,
  4.5, 1.6667, 4.1667, 4.3333, 3.0, 3.3333, 2.6667, 3.6667, 2.5, 3.3333, 3.3333,
  4.1667, 3.3333, 2.8333, 4.3333, 5.0, 3.0, 3.0, 2.6667, 4.0, 3.3333, 4.6667,
  4.5, 2.3333, 4.0, 4.0, 1.0, 2.6667, 2.3333, 2.8333, 3.5, 3.8333, 3.3333, 3.0,
  4.0, 4.1667, 3.3333, 3.1667, 2.5, 2.3333, 2.8333, 2.0, 3.3333, 4.0, 4.5,
  3.3333, 3.0, 3.6667, 2.5, 3.1667, 4.0, 3.1667, 4.0, 3.1667, 2.6667, 4.8333,
  2.6667, 3.0, 3.3333, 4.3333, 4.8333, 2.3333, 3.0, 4.6667, 2.6667, 3.0, 3.6667,
  2.1667, 3.8333, 4.1667, 3.0, 3.1667, 2.6667, 4.3333, 3.0, 1.5, 3.0, 2.3333,
  4.1667, 2.1667, 4.6667, 3.5, 2.5, 1.0, 3.3333, 3.0, 4.5, 2.6667, 3.1667, 4.0,
  4.1667, 4.0, 4.3333, 3.6667, 4.3333, 3.3333, 4.0, 3.0, 2.1667, 3.3333, 3.5,
  2.8333, 4.3333, 4.0, 2.0, 2.5, 4.5, 3.5, 3.3333, 3.3333, 3.5, 4.0, 4.0,
  1.8333, 3.1667, 4.5, 3.8333, 2.1667, 3.0, 3.6667, 2.0, 3.8333, 5.0, 2.8333,
  4.1667, 4.0, 2.6667, 3.8333, 4.0, 4.0, 3.5, 1.5, 4.0, 4.0, 4.6667, 2.8333,
  4.0, 4.3333, 3.5, 4.5, 4.3333, 3.6667, 3.0, 3.6667, 4.1667, 2.3333, 3.3333,
  3.5, 4.0, 3.3333, 2.0, 4.0, 2.0, 1.8333, 2.3333, 2.0, 2.1667, 1.0, 3.3333,
  3.3333, 3.8333, 4.3333, 1.3333, 3.5, 2.6667, 2.3333, 3.6667, 3.1667, 3.8333,
  2.6667, 1.8333, 4.0, 3.1667, 1.3333, 3.5, 4.1667, 3.6667, 3.8333, 2.3333, 2.0,
  3.8333, 2.5, 3.6667, 2.3333, 4.6667, 3.0, 3.1667, 2.0, 3.6667, 5.0, 3.6667,
  3.0, 3.8333, 2.5, 4.0, 5.0, 4.1667, 4.5, 4.0, 1.8333, 3.1667, 4.5, 2.8333,
  3.3333, 2.0, 2.5, 2.5, 3.3333, 2.5, 2.6667, 3.0, 2.0, 2.1667, 3.1667, 3.3333,
  3.3333, 3.5, 3.5, 4.0, 3.5, 3.3333, 4.0, 4.3333, 1.0, 3.0, 3.8333, 4.1667,
  3.1667, 3.0, 3.1667, 4.0, 3.6667, 3.0, 2.3333,
];

export const productServiceData = [
  3.0, 1.0, 2.1111, 2.5556, 3.5556, 1.4444, 1.8889, 1.4444, 2.0, 1.2222, 2.7778,
  2.3333, 2.4444, 2.0, 1.3333, 2.2222, 2.5556, 2.8889, 2.7778, 3.2222, 1.1111,
  1.4444, 2.2222, 1.8889, 1.2222, 2.2222, 2.1111, 1.0, 2.0, 2.5556, 1.3333, 1.0,
  1.0, 1.7778, 1.8889, 2.1111, 2.0, 3.0, 1.4444, 2.3333, 2.0, 1.8889, 1.4444,
  2.5556, 2.5556, 1.7778, 2.3333, 2.3333, 3.1111, 1.6667, 1.0, 1.6667, 2.0,
  2.3333, 2.3333, 1.1111, 1.8889, 1.8889, 3.0, 1.8889, 1.3333, 2.3333, 2.3333,
  3.1111, 1.2222, 2.5556, 2.0, 2.5556, 1.8889, 2.1111, 2.4444, 2.3333, 2.6667,
  2.7778, 1.7778, 2.6667, 1.0, 3.1111, 2.6667, 1.7778, 1.8889, 1.3333, 2.4444,
  2.2222, 1.6667, 3.4444, 2.2222, 1.7778, 2.3333, 2.0, 2.3333, 2.1111, 2.4444,
  1.6667, 2.3333, 2.3333, 2.5556, 3.1111, 2.1111, 3.3333, 3.1111, 3.3333,
  3.3333, 1.4444, 2.8889, 3.5556, 1.8889, 2.7778, 1.6667, 2.2222, 1.5556,
  1.8889, 2.2222, 1.4444, 2.2222, 2.4444, 1.4444, 2.8889, 2.5556, 2.4444,
  2.6667, 1.8889, 2.3333, 3.1111, 1.3333, 2.3333, 2.2222, 2.3333, 2.6667,
  2.8889, 2.3333, 1.0, 2.2222, 3.3333, 1.3333, 1.3333, 2.7778, 2.3333, 3.6667,
  1.8889, 1.5556, 2.3333, 3.7778, 2.0, 1.0, 1.6667, 2.4444, 1.4444, 1.4444,
  1.7778, 3.2222, 3.0, 2.5556, 3.0, 2.6667, 1.4444, 2.7778, 2.0, 2.2222, 1.8889,
  2.6667, 2.3333, 2.3333, 1.5556, 2.0, 2.3333, 3.1111, 2.1111, 2.7778, 3.0,
  2.6667, 2.2222, 2.1111, 2.2222, 2.2222, 2.5556, 1.6667, 1.4444, 1.3333,
  1.8889, 2.3333, 1.8889, 2.3333, 2.3333, 2.3333, 2.3333, 2.1111, 1.8889,
  3.8889, 2.2222, 1.8889, 1.0, 1.8889, 2.5556, 2.1111, 1.8889, 2.2222, 2.7778,
  2.0, 2.1111, 3.0, 2.3333, 1.4444, 2.1111, 1.2222, 1.0, 2.0, 1.7778, 1.5556,
  2.8889, 2.8889, 1.7778, 1.8889, 2.3333, 2.3333, 2.3333, 2.8889, 2.4444, 1.0,
  2.0, 3.1111, 1.4444, 2.5556, 3.2222, 2.0, 1.0, 2.2222, 2.7778, 1.8889, 2.5556,
  2.1111, 4.1111, 2.0, 3.1111, 1.3333, 2.3333, 2.4444, 2.6667, 1.5556, 3.5556,
  1.6667, 2.5556, 1.6667, 1.8889, 2.1111, 2.6667, 1.8889, 1.6667, 2.0, 2.0,
  2.8889, 2.2222, 1.0, 2.1111, 2.0, 2.7778, 3.3333, 2.5556, 3.0, 1.5556, 1.0,
  2.2222, 2.6667, 3.5556, 1.0, 2.6667, 2.7778, 1.5556, 1.7778, 3.3333, 1.6667,
  1.8889, 1.8889, 2.4444, 2.2222, 1.4444, 1.6667, 1.5556, 2.3333, 2.7778,
  3.1111, 1.4444, 2.1111, 2.2222, 2.8889, 1.6667, 3.8889, 1.4444, 2.6667,
  2.5556, 2.0, 1.2222, 1.7778, 3.5556, 1.6667, 2.0, 3.0, 2.2222, 2.3333, 2.4444,
  2.2222, 3.3333, 2.3333, 2.3333, 1.4444, 1.0, 2.8889, 2.3333, 1.7778, 2.2222,
  1.0, 2.8889, 1.6667, 2.5556, 2.3333, 1.4444, 2.7778, 2.7778, 2.6667, 2.4444,
  2.8889, 1.4444, 1.1111, 1.8889, 1.3333, 3.2222, 2.1111, 1.0, 2.7778, 1.2222,
  1.6667, 2.1111, 1.6667, 2.2222, 1.4444, 2.1111, 2.4444, 3.0, 2.3333, 1.8889,
  2.3333, 2.2222, 1.0, 2.0, 1.6667, 1.8889, 1.7778, 1.4444, 3.2222, 1.3333,
  1.6667, 1.6667, 1.0, 1.0, 1.4444, 2.1111, 1.4444, 2.4444, 1.5556, 3.3333, 3.0,
  2.0, 2.7778, 2.7778, 2.0, 1.8889, 1.8889, 2.3333, 2.0, 1.2222, 1.0, 1.8889,
  2.7778, 2.4444, 1.3333, 2.1111, 2.1111, 2.6667, 3.2222, 1.4444, 1.6667, 3.0,
  1.2222, 1.4444, 1.3333, 2.3333, 3.0, 1.6667, 1.0, 1.8889, 2.2222, 1.2222, 2.0,
  1.0, 2.7778, 2.1111, 2.2222, 2.8889, 2.7778, 2.8889, 1.0, 2.5556, 2.1111,
  1.6667, 2.0, 2.7778, 1.8889, 2.4444, 2.5556, 2.2222, 1.6667,
];

export const computerAidedData = [
  3.0, 1.0, 3.6667, 3.3333, 3.3333, 1.0, 3.3333, 2.3333, 2.0, 1.3333, 2.6667,
  5.0, 2.6667, 2.3333, 2.3333, 2.0, 4.3333, 2.6667, 2.0, 1.0, 2.6667, 2.3333,
  2.0, 2.3333, 1.0, 3.3333, 1.0, 1.0, 1.3333, 3.3333, 1.0, 2.3333, 3.6667, 2.0,
  2.3333, 1.3333, 1.0, 4.0, 2.3333, 2.0, 1.0, 3.6667, 3.6667, 1.3333, 1.0, 1.0,
  2.0, 2.6667, 4.0, 3.3333, 3.6667, 5.0, 1.0, 3.6667, 1.0, 2.0, 2.3333, 3.3333,
  3.6667, 1.0, 1.0, 2.0, 1.0, 2.3333, 1.0, 5.0, 1.0, 3.0, 1.3333, 2.6667,
  3.6667, 2.3333, 3.6667, 4.3333, 3.0, 3.0, 1.0, 3.6667, 1.0, 3.0, 1.0, 1.0,
  3.0, 3.6667, 2.6667, 2.3333, 4.0, 1.3333, 1.0, 1.3333, 1.0, 2.0, 2.6667,
  1.3333, 2.0, 2.6667, 1.0, 3.6667, 3.0, 1.0, 1.6667, 1.0, 2.6667, 1.0, 3.6667,
  2.3333, 2.6667, 2.0, 3.6667, 2.6667, 3.3333, 2.0, 2.3333, 3.3333, 4.0, 1.3333,
  1.0, 5.0, 4.0, 3.3333, 2.0, 2.3333, 2.0, 3.3333, 3.3333, 2.6667, 2.0, 2.3333,
  2.3333, 2.0, 1.6667, 1.0, 2.6667, 4.0, 3.6667, 2.6667, 1.6667, 3.0, 3.6667,
  2.3333, 2.0, 2.6667, 4.3333, 1.0, 1.0, 1.6667, 3.3333, 1.6667, 3.6667, 2.3333,
  2.6667, 2.0, 4.0, 3.0, 3.6667, 3.0, 2.0, 3.0, 3.6667, 2.6667, 3.3333, 2.3333,
  1.0, 2.3333, 5.0, 3.0, 4.0, 2.0, 2.3333, 5.0, 3.0, 1.6667, 1.0, 2.6667, 5.0,
  5.0, 1.6667, 4.3333, 2.0, 4.6667, 2.3333, 1.0, 2.6667, 2.0, 4.0, 1.0, 2.6667,
  1.0, 2.3333, 3.6667, 1.3333, 1.0, 3.6667, 1.3333, 3.3333, 2.3333, 3.3333, 5.0,
  2.0, 4.6667, 3.6667, 3.6667, 3.6667, 1.0, 2.3333, 1.0, 1.0, 3.6667, 2.3333,
  2.0, 3.3333, 1.3333, 3.6667, 1.0, 2.3333, 3.3333, 4.0, 2.0, 2.3333, 2.0,
  4.3333, 3.6667, 2.3333, 3.6667, 3.0, 1.0, 2.3333, 2.3333, 2.3333, 3.3333,
  3.3333, 2.3333, 3.0, 5.0, 2.0, 3.6667, 3.0, 3.6667, 2.6667, 2.0, 2.0, 3.3333,
  2.0, 1.0, 2.6667, 2.0, 3.6667, 2.3333, 1.6667, 2.3333, 1.6667, 2.6667, 1.0,
  2.0, 1.0, 5.0, 5.0, 2.0, 3.3333, 3.0, 1.0, 1.6667, 2.6667, 4.6667, 1.0,
  3.3333, 1.0, 1.0, 5.0, 2.0, 5.0, 1.0, 2.6667, 3.3333, 4.0, 1.0, 1.0, 2.6667,
  3.3333, 2.6667, 3.0, 1.0, 2.0, 3.0, 3.3333, 2.0, 2.3333, 1.6667, 3.3333, 4.0,
  1.0, 1.0, 3.3333, 3.3333, 1.0, 1.0, 3.6667, 2.6667, 1.0, 3.0, 1.3333, 2.3333,
  4.0, 2.3333, 1.0, 1.0, 3.6667, 1.0, 4.6667, 3.0, 1.0, 3.6667, 4.0, 3.6667,
  1.0, 2.6667, 3.0, 2.6667, 3.3333, 2.6667, 1.0, 1.0, 1.0, 3.3333, 2.3333,
  2.3333, 1.0, 1.0, 4.0, 1.0, 2.0, 1.3333, 2.0, 1.3333, 1.0, 1.6667, 2.6667,
  2.6667, 1.0, 5.0, 2.0, 2.0, 1.0, 2.0, 1.0, 1.0, 3.0, 1.0, 2.3333, 3.3333,
  3.6667, 2.3333, 1.0, 2.3333, 1.0, 2.6667, 1.0, 2.6667, 3.3333, 5.0, 1.3333,
  2.3333, 3.3333, 3.6667, 3.0, 2.3333, 2.3333, 3.0, 2.3333, 2.0, 1.0, 3.6667,
  3.3333, 3.0, 5.0, 2.3333, 1.0, 1.3333, 2.3333, 1.0, 3.3333, 3.0, 1.0, 2.0,
  1.6667, 2.0, 4.0, 2.0, 1.0, 2.3333, 2.0, 2.3333, 3.3333, 1.0, 3.6667, 4.6667,
  1.6667, 1.0, 5.0, 1.0, 1.0, 2.0, 2.0, 3.0, 2.0, 2.3333, 1.0, 1.3333, 4.0,
  1.3333, 1.0,
];

export const integrationData = [
  3.0, 1.6667, 3.5, 3.5, 3.5, 2.1667, 1.8333, 2.1667, 2.1667, 2.3333, 3.1667,
  2.0, 3.1667, 3.1667, 2.3333, 3.1667, 3.3333, 4.5, 2.1667, 2.3333, 2.1667, 2.0,
  3.0, 2.1667, 1.0, 2.6667, 1.0, 1.0, 1.5, 3.0, 1.5, 1.0, 1.0, 2.3333, 2.3333,
  2.0, 1.5, 3.8333, 1.6667, 1.6667, 4.0, 1.6667, 1.0, 2.5, 4.8333, 2.3333,
  3.1667, 3.3333, 3.0, 2.1667, 1.3333, 3.6667, 1.8333, 3.5, 1.6667, 3.3333, 1.0,
  2.3333, 3.5, 1.1667, 1.0, 4.0, 1.5, 3.0, 1.1667, 1.3333, 4.0, 1.8333, 2.8333,
  3.3333, 2.1667, 3.0, 4.0, 2.1667, 2.0, 2.3333, 1.3333, 4.3333, 3.3333, 4.0,
  1.0, 1.5, 3.3333, 2.5, 3.1667, 2.6667, 2.8333, 1.5, 2.3333, 2.5, 1.1667,
  2.1667, 2.6667, 2.3333, 3.0, 2.8333, 2.6667, 3.1667, 2.5, 2.6667, 1.6667,
  2.1667, 2.5, 2.3333, 1.0, 2.3333, 3.0, 2.8333, 3.5, 2.5, 2.1667, 2.1667, 1.5,
  1.1667, 2.1667, 2.5, 1.6667, 3.8333, 3.3333, 3.0, 2.8333, 3.0, 1.6667, 4.0,
  2.1667, 2.0, 1.3333, 2.8333, 2.3333, 3.8333, 2.8333, 1.0, 2.3333, 4.0, 1.6667,
  3.0, 1.8333, 2.3333, 3.3333, 1.0, 3.3333, 2.0, 4.0, 1.6667, 1.0, 1.0, 1.8333,
  1.0, 1.0, 2.1667, 2.0, 2.5, 3.0, 2.8333, 1.6667, 1.5, 3.1667, 2.0, 3.1667,
  3.1667, 3.0, 2.1667, 1.3333, 2.1667, 3.0, 2.8333, 3.0, 3.8333, 1.0, 3.1667,
  3.0, 2.5, 2.6667, 2.5, 3.3333, 4.0, 1.5, 5.0, 1.0, 4.3333, 4.3333, 1.0, 2.5,
  3.1667, 3.8333, 1.6667, 3.5, 1.0, 3.8333, 3.0, 1.6667, 1.0, 2.5, 3.0, 2.3333,
  3.1667, 3.0, 3.0, 3.1667, 4.0, 4.0, 5.0, 1.6667, 1.0, 1.0, 1.8333, 1.5,
  4.6667, 2.0, 4.0, 2.8333, 1.0, 4.3333, 2.5, 2.8333, 2.1667, 2.6667, 2.6667,
  1.0, 2.5, 4.0, 3.1667, 2.6667, 3.0, 2.8333, 2.3333, 2.3333, 2.6667, 1.5,
  4.3333, 3.8333, 1.0, 4.0, 3.8333, 2.1667, 2.8333, 4.3333, 3.0, 2.3333, 2.1667,
  4.5, 3.3333, 1.5, 1.0, 2.5, 3.3333, 3.0, 1.6667, 2.8333, 1.0, 3.1667, 2.0,
  1.6667, 2.1667, 2.5, 3.6667, 3.0, 2.3333, 3.1667, 1.0, 1.0, 1.8333, 2.3333,
  3.0, 1.0, 3.6667, 3.5, 3.1667, 4.5, 3.3333, 4.8333, 1.6667, 2.5, 3.0, 2.5,
  1.8333, 1.0, 3.6667, 3.0, 2.0, 4.3333, 1.0, 1.8333, 2.1667, 4.0, 3.1667,
  4.1667, 2.3333, 3.3333, 2.5, 1.0, 1.6667, 4.3333, 2.3333, 1.5, 3.5, 2.6667,
  1.6667, 2.6667, 2.8333, 1.8333, 2.3333, 2.8333, 2.0, 2.5, 3.3333, 3.1667,
  1.1667, 1.0, 3.6667, 1.0, 4.1667, 2.0, 2.6667, 1.0, 2.1667, 4.0, 4.1667,
  2.3333, 2.0, 3.5, 1.6667, 1.0, 4.3333, 1.0, 1.8333, 4.3333, 1.3333, 3.6667,
  1.1667, 1.0, 2.0, 2.0, 2.1667, 1.0, 2.3333, 2.3333, 2.0, 1.0, 2.3333, 2.3333,
  2.0, 1.0, 2.5, 1.0, 2.0, 3.0, 1.6667, 2.5, 2.6667, 3.0, 3.3333, 2.3333, 2.0,
  2.3333, 2.3333, 1.3333, 2.3333, 3.1667, 2.5, 4.3333, 3.5, 3.5, 2.0, 2.3333,
  1.0, 2.5, 2.5, 1.6667, 1.5, 1.1667, 3.5, 4.3333, 3.0, 4.1667, 2.8333, 1.8333,
  2.3333, 3.0, 3.5, 2.8333, 2.6667, 1.5, 1.6667, 1.8333, 2.6667, 3.8333, 1.8333,
  1.0, 2.0, 3.3333, 1.0, 2.6667, 1.5, 4.5, 4.1667, 4.0, 3.8333, 4.3333, 2.8333,
  1.3333, 2.0, 2.8333, 2.0, 1.5, 2.5, 1.5, 2.6667, 1.5, 2.8333, 1.0,
];

export const automationData = [
  2.3333, 1.6667, 1.0, 1.0, 4.3333, 1.6667, 2.1667, 1.5, 2.5, 1.0, 2.3333, 1.5,
  2.3333, 1.8333, 1.3333, 2.3333, 2.3333, 3.1667, 1.8333, 1.6667, 2.0, 1.0,
  2.3333, 1.0, 1.0, 2.5, 1.0, 1.0, 1.3333, 2.1667, 1.3333, 1.0, 1.0, 1.8333,
  1.6667, 1.5, 1.0, 3.8333, 1.0, 3.1667, 2.5, 1.3333, 1.6667, 1.6667, 3.1667,
  1.0, 3.3333, 2.0, 2.6667, 1.8333, 1.3333, 2.3333, 1.5, 2.1667, 1.0, 2.1667,
  1.0, 1.6667, 4.1667, 1.1667, 1.0, 1.6667, 1.6667, 1.0, 1.3333, 1.0, 2.3333,
  2.0, 2.8333, 2.3333, 2.3333, 1.8333, 1.6667, 1.8333, 2.3333, 2.1667, 2.1667,
  2.8333, 2.3333, 2.1667, 2.0, 2.0, 1.5, 1.5, 4.0, 2.0, 2.0, 1.6667, 1.0, 1.5,
  1.6667, 2.0, 2.8333, 1.3333, 1.6667, 3.5, 1.6667, 1.8333, 3.0, 1.5, 1.8333,
  1.1667, 3.8333, 1.8333, 2.6667, 1.0, 1.8333, 1.5, 3.1667, 1.5, 2.1667, 2.0,
  1.1667, 1.0, 2.0, 1.6667, 1.3333, 2.8333, 3.3333, 2.1667, 2.0, 2.0, 1.0, 2.5,
  1.6667, 2.0, 1.3333, 1.0, 1.5, 3.0, 2.1667, 1.0, 2.5, 2.3333, 2.0, 1.6667,
  1.8333, 2.6667, 2.3333, 2.3333, 2.6667, 1.6667, 3.3333, 1.0, 1.0, 1.0, 2.0,
  1.5, 1.6667, 2.0, 2.1667, 2.5, 2.3333, 1.6667, 1.1667, 1.5, 2.0, 1.1667, 2.5,
  2.5, 2.6667, 2.3333, 1.3333, 2.0, 1.6667, 3.0, 2.0, 1.3333, 1.0, 1.0, 2.6667,
  1.5, 2.6667, 2.1667, 3.6667, 2.3333, 2.3333, 4.0, 1.0, 2.3333, 2.3333, 1.0,
  1.0, 3.3333, 3.1667, 1.1667, 2.3333, 1.5, 3.1667, 1.0, 2.0, 1.3333, 1.6667,
  2.5, 2.5, 1.6667, 2.6667, 1.0, 2.6667, 2.1667, 2.3333, 4.3333, 1.0, 1.3333,
  1.0, 1.0, 1.3333, 1.3333, 1.8333, 2.5, 2.5, 1.5, 2.1667, 2.3333, 1.0, 2.0,
  2.0, 1.6667, 1.0, 2.3333, 3.1667, 2.3333, 2.1667, 1.6667, 2.0, 1.0, 1.5, 3.0,
  1.0, 2.5, 2.5, 1.0, 3.8333, 1.3333, 2.3333, 3.1667, 4.0, 3.1667, 1.0, 2.6667,
  1.0, 2.6667, 1.5, 1.0, 2.3333, 3.3333, 3.0, 1.3333, 1.5, 1.0, 2.8333, 2.0,
  1.0, 1.3333, 1.6667, 3.3333, 2.3333, 2.6667, 2.1667, 1.0, 1.0, 1.5, 2.0, 1.0,
  1.0, 3.0, 1.5, 2.0, 2.0, 3.1667, 1.0, 1.5, 3.3333, 3.3333, 2.3333, 1.0, 1.0,
  2.0, 1.0, 1.8333, 3.0, 1.0, 2.3333, 2.3333, 3.0, 2.0, 2.0, 1.6667, 2.3333,
  2.8333, 2.0, 1.5, 3.5, 2.3333, 1.3333, 2.0, 1.0, 2.0, 1.1667, 2.6667, 1.8333,
  1.6667, 2.8333, 1.0, 1.6667, 1.0, 3.5, 1.5, 1.1667, 3.5, 2.5, 2.6667, 1.8333,
  2.3333, 1.0, 3.6667, 4.6667, 2.3333, 2.3333, 2.0, 2.3333, 2.1667, 1.3333,
  2.1667, 1.0, 2.6667, 2.8333, 1.0, 3.3333, 1.5, 1.0, 2.0, 2.0, 3.3333, 1.0,
  2.0, 1.5, 2.0, 1.0, 1.0, 3.0, 2.0, 1.0, 2.0, 1.0, 2.5, 1.0, 1.1667, 4.8333,
  1.5, 1.8333, 2.5, 1.6667, 2.3333, 1.5, 2.0, 1.0, 2.0, 2.3333, 4.1667, 1.3333,
  3.5, 3.5, 1.0, 2.0, 1.0, 3.6667, 2.0, 1.5, 2.3333, 1.0, 2.3333, 4.0, 3.5,
  2.3333, 2.6667, 1.0, 2.1667, 1.0, 1.0, 1.8333, 3.0, 1.1667, 1.3333, 1.0,
  1.1667, 2.6667, 1.8333, 1.0, 1.0, 2.3333, 1.0, 2.0, 2.3333, 3.0, 3.5, 2.3333,
  2.3333, 3.8333, 2.5, 1.0, 2.0, 2.3333, 1.0, 1.6667, 1.3333, 1.5, 1.8333, 1.5,
  2.5, 1.0,
];

export const realtimeData = [
  2.6667, 1.0, 2.3333, 1.8333, 4.3333, 2.3333, 2.1667, 2.3333, 2.8333, 1.0,
  3.1667, 1.0, 2.1667, 3.8333, 1.3333, 2.3333, 3.0, 4.0, 2.0, 2.3333, 3.3333,
  1.0, 2.6667, 1.0, 1.0, 2.0, 1.5, 1.3333, 1.8333, 2.6667, 1.6667, 1.0, 1.3333,
  2.0, 2.0, 1.6667, 1.0, 3.8333, 3.0, 2.1667, 3.1667, 1.6667, 1.0, 1.6667,
  2.8333, 2.5, 3.6667, 2.3333, 2.6667, 2.0, 2.0, 2.3333, 2.0, 3.0, 2.3333, 2.5,
  1.0, 2.0, 3.3333, 1.8333, 1.5, 3.0, 1.6667, 2.6667, 2.1667, 2.3333, 3.1667,
  1.8333, 3.5, 3.6667, 2.8333, 2.0, 2.3333, 2.0, 2.5, 3.0, 1.5, 3.1667, 2.3333,
  3.3333, 2.0, 1.3333, 1.5, 1.6667, 1.6667, 2.6667, 2.5, 1.5, 2.0, 3.0, 2.1667,
  2.0, 3.0, 1.8333, 3.3333, 2.6667, 2.6667, 2.1667, 2.6667, 2.3333, 2.3333,
  1.8333, 3.0, 2.3333, 2.3333, 2.3333, 4.0, 3.5, 2.6667, 2.6667, 3.6667, 2.3333,
  3.0, 1.3333, 2.0, 2.6667, 2.1667, 4.0, 2.6667, 2.5, 2.1667, 2.8333, 1.5,
  3.3333, 4.0, 2.1667, 1.8333, 2.6667, 2.3333, 5.0, 2.5, 1.0, 2.8333, 3.3333,
  1.0, 2.1667, 1.5, 2.6667, 3.5, 3.6667, 2.3333, 1.8333, 3.0, 2.1667, 2.3333,
  1.0, 1.6667, 1.0, 2.3333, 1.8333, 1.6667, 3.0, 3.0, 2.0, 1.1667, 1.0, 2.0,
  2.0, 3.3333, 3.6667, 3.0, 3.5, 2.3333, 1.8333, 2.3333, 2.8333, 2.6667, 1.6667,
  2.3333, 2.0, 3.1667, 1.8333, 3.6667, 2.1667, 3.0, 2.3333, 2.1667, 3.3333, 1.0,
  2.3333, 3.5, 1.0, 2.6667, 3.6667, 2.0, 1.5, 2.6667, 2.3333, 2.6667, 1.6667,
  2.3333, 1.6667, 2.0, 2.5, 2.1667, 2.3333, 3.3333, 3.3333, 2.8333, 2.5, 5.0,
  5.0, 2.5, 1.0, 1.3333, 1.0, 3.0, 4.5, 2.6667, 3.6667, 2.5, 1.8333, 5.0, 3.0,
  1.6667, 2.1667, 2.6667, 3.1667, 1.0, 1.6667, 3.1667, 2.5, 2.3333, 2.3333, 2.5,
  3.0, 2.5, 3.3333, 1.0, 4.0, 3.3333, 2.0, 4.0, 3.1667, 2.0, 3.0, 4.0, 3.3333,
  1.0, 3.0, 3.6667, 3.0, 2.1667, 2.3333, 2.6667, 2.8333, 3.6667, 1.1667, 2.0,
  1.0, 3.0, 2.6667, 2.3333, 1.1667, 2.6667, 3.3333, 1.5, 2.3333, 3.0, 1.6667,
  3.0, 2.5, 2.3333, 1.8333, 1.0, 3.6667, 2.1667, 2.5, 4.8333, 3.8333, 5.0,
  2.3333, 3.1667, 3.0, 2.6667, 2.0, 1.8333, 2.3333, 2.0, 3.0, 3.8333, 1.0, 2.0,
  1.5, 3.8333, 3.0, 2.8333, 2.6667, 3.0, 3.8333, 1.0, 1.5, 4.3333, 3.0, 1.8333,
  2.6667, 1.0, 2.0, 1.6667, 3.5, 2.5, 2.3333, 3.0, 2.3333, 1.0, 3.0, 3.6667,
  3.0, 1.0, 4.1667, 2.0, 4.1667, 2.6667, 2.0, 1.0, 2.6667, 4.5, 4.5, 2.3333,
  2.0, 3.6667, 1.3333, 1.1667, 2.3333, 1.0, 2.3333, 4.3333, 3.0, 2.8333, 1.0,
  1.0, 1.8333, 2.6667, 3.6667, 2.3333, 3.0, 3.0, 2.0, 1.0, 1.6667, 2.5, 2.0,
  1.0, 2.6667, 2.0, 2.5, 2.5, 2.5, 3.5, 2.3333, 3.3333, 3.5, 2.5, 3.3333,
  1.3333, 2.3333, 1.3333, 2.8333, 4.3333, 2.0, 2.1667, 2.8333, 3.6667, 2.6667,
  2.0, 2.1667, 3.0, 2.6667, 2.0, 2.1667, 1.8333, 2.6667, 3.6667, 4.0, 4.1667,
  4.0, 2.3333, 1.0, 4.0, 3.0, 2.6667, 2.8333, 2.6667, 1.6667, 1.6667, 3.0, 4.0,
  2.3333, 1.6667, 2.0, 2.6667, 1.0, 2.6667, 2.6667, 3.6667, 3.5, 4.5, 3.5, 4.0,
  2.5, 1.0, 2.0, 3.5, 1.6667, 1.6667, 2.8333, 3.0, 1.8333, 1.6667, 3.3333, 1.0,
];

export const analyticsData = [
  2.0, 1.0, 1.0, 1.0, 3.5556, 1.0, 2.1111, 1.2222, 2.5556, 1.0, 2.0, 1.0,
  2.1111, 2.6667, 1.0, 2.2222, 2.1111, 3.2222, 2.2222, 1.8889, 2.1111, 1.2222,
  2.0, 1.0, 1.0, 2.0, 1.0, 1.0, 1.4444, 2.6667, 1.8889, 1.0, 1.0, 2.0, 1.0,
  1.2222, 1.0, 2.3333, 1.0, 1.0, 2.4444, 1.3333, 1.0, 1.7778, 1.0, 1.5556, 2.0,
  2.1111, 2.1111, 2.0, 1.0, 1.2222, 1.0, 2.0, 1.0, 1.0, 1.0, 1.7778, 2.5556,
  1.1111, 1.0, 1.4444, 1.0, 1.0, 1.1111, 1.0, 1.4444, 1.6667, 2.0, 1.8889,
  1.8889, 1.0, 1.4444, 1.0, 2.6667, 2.2222, 1.0, 1.8889, 1.2222, 2.0, 1.0,
  1.1111, 1.1111, 1.0, 1.7778, 1.4444, 1.5556, 1.4444, 1.0, 1.6667, 1.2222, 2.0,
  2.4444, 1.0, 1.5556, 2.4444, 1.6667, 1.8889, 2.0, 2.0, 1.0, 1.8889, 2.1111,
  1.0, 1.0, 1.0, 2.0, 1.0, 1.7778, 1.6667, 1.0, 2.0, 1.2222, 2.0, 2.0, 1.5556,
  1.0, 2.2222, 2.6667, 2.0, 2.0, 1.5556, 1.0, 2.1111, 1.7778, 2.0, 1.0, 1.0,
  2.0, 2.4444, 2.1111, 1.0, 2.1111, 2.2222, 1.0, 1.4444, 1.2222, 2.2222, 1.4444,
  1.0, 2.0, 1.7778, 3.0, 1.0, 1.0, 1.0, 1.8889, 1.0, 1.0, 1.3333, 1.5556, 2.0,
  2.2222, 1.3333, 1.3333, 1.3333, 2.0, 1.3333, 1.1111, 1.8889, 2.0, 1.4444,
  2.7778, 1.3333, 1.0, 1.8889, 2.1111, 1.1111, 1.0, 1.6667, 2.0, 1.0, 1.8889,
  2.1111, 1.4444, 1.6667, 1.1111, 1.0, 1.0, 2.5556, 1.4444, 1.0, 2.4444, 1.8889,
  2.0, 1.1111, 2.4444, 1.0, 3.5556, 1.4444, 2.0, 1.6667, 1.0, 2.1111, 2.0, 1.0,
  2.2222, 1.4444, 2.2222, 2.1111, 2.3333, 2.5556, 2.1111, 1.3333, 1.0, 1.0,
  1.4444, 1.6667, 1.5556, 2.3333, 2.3333, 1.0, 1.0, 1.0, 2.1111, 2.0, 2.0,
  1.5556, 1.0, 1.4444, 1.8889, 1.0, 2.1111, 1.8889, 2.0, 1.0, 1.3333, 2.7778,
  1.0, 2.7778, 2.3333, 1.4444, 2.2222, 1.7778, 2.0, 1.8889, 3.4444, 2.2222, 1.0,
  2.5556, 1.0, 2.1111, 1.4444, 1.0, 2.0, 2.0, 1.3333, 1.0, 1.0, 1.0, 1.5556,
  2.0, 1.0, 1.3333, 1.4444, 1.4444, 1.6667, 2.0, 1.7778, 1.0, 1.0, 1.5556, 2.0,
  1.0, 1.0, 2.2222, 1.0, 1.7778, 2.7778, 3.3333, 2.5556, 1.0, 2.4444, 3.7778,
  2.2222, 1.0, 1.0, 1.5556, 1.0, 1.8889, 3.3333, 1.0, 1.8889, 2.0, 2.1111, 2.0,
  1.7778, 1.0, 1.8889, 2.6667, 1.0, 1.0, 3.1111, 2.2222, 1.2222, 1.0, 1.0, 2.0,
  1.1111, 2.0, 2.3333, 1.4444, 2.2222, 1.0, 1.4444, 1.0, 2.8889, 1.0, 1.0,
  2.5556, 1.0, 2.0, 2.0, 2.0, 1.0, 3.0, 1.2222, 2.0, 2.0, 2.0, 2.6667, 1.0, 1.0,
  1.0, 1.0, 1.0, 1.4444, 1.0, 2.3333, 1.2222, 1.0, 2.1111, 2.0, 1.5556, 1.0,
  2.0, 2.0, 2.0, 1.0, 1.0, 2.4444, 2.0, 1.0, 2.1111, 1.0, 1.6667, 1.0, 1.3333,
  2.2222, 1.8889, 1.2222, 2.4444, 1.0, 1.6667, 1.4444, 2.0, 1.0, 2.0, 1.6667,
  2.3333, 1.0, 1.3333, 4.0, 1.0, 1.5556, 1.0, 1.4444, 2.0, 2.0, 1.3333, 1.0,
  1.4444, 3.1111, 2.2222, 1.4444, 2.1111, 1.0, 1.5556, 1.0, 1.0, 2.0, 1.0, 1.0,
  1.6667, 1.0, 1.3333, 2.4444, 1.8889, 1.1111, 1.0, 2.6667, 1.0, 2.0, 1.2222,
  1.0, 2.2222, 2.7778, 2.2222, 2.1111, 1.8889, 1.0, 2.0, 2.5556, 1.0, 1.3333,
  1.0, 2.2222, 1.3333, 1.3333, 1.6667, 1.0,
];

export const supplyChainData = [
  3.0, 1.0, 3.0, 1.1667, 3.8333, 1.0, 2.6667, 1.5, 4.3333, 3.0, 2.6667, 1.3333,
  2.8333, 4.5, 1.6667, 3.1667, 3.0, 3.5, 3.0, 1.6667, 2.5, 2.6667, 2.5, 1.0,
  1.0, 2.6667, 1.6667, 2.6667, 1.5, 3.5, 1.1667, 1.6667, 1.0, 2.8333, 2.6667,
  1.5, 1.0, 3.1667, 2.6667, 2.8333, 3.3333, 1.3333, 1.6667, 1.5, 2.6667, 1.1667,
  3.1667, 3.0, 2.5, 2.3333, 2.1667, 2.3333, 2.5, 2.6667, 1.3333, 2.8333, 1.3333,
  1.1667, 3.0, 1.3333, 1.5, 3.3333, 3.0, 1.3333, 1.5, 1.0, 3.0, 1.6667, 2.0,
  2.6667, 2.1667, 2.3333, 2.3333, 2.8333, 4.0, 3.0, 2.8333, 3.0, 2.5, 3.1667,
  1.5, 1.6667, 1.3333, 2.3333, 2.8333, 3.0, 2.3333, 1.3333, 1.0, 2.8333, 1.8333,
  2.6667, 2.6667, 2.3333, 3.0, 2.5, 1.6667, 2.8333, 3.0, 2.3333, 1.0, 2.1667,
  2.1667, 1.8333, 3.5, 1.6667, 1.8333, 2.3333, 2.3333, 2.8333, 2.1667, 3.1667,
  2.6667, 2.3333, 2.0, 2.1667, 1.6667, 3.0, 2.6667, 2.3333, 2.6667, 2.8333,
  2.1667, 1.8333, 2.6667, 2.3333, 2.1667, 1.3333, 1.8333, 2.5, 2.5, 1.0, 3.1667,
  3.1667, 2.3333, 1.5, 3.1667, 3.0, 1.3333, 1.5, 2.0, 2.0, 1.5, 1.3333, 1.3333,
  1.0, 2.0, 2.1667, 2.6667, 2.0, 2.5, 2.0, 2.5, 1.8333, 1.0, 1.0, 2.0, 2.3333,
  1.6667, 2.3333, 2.8333, 1.0, 3.8333, 1.5, 4.3333, 3.0, 2.8333, 3.1667, 2.3333,
  2.3333, 2.6667, 2.8333, 2.3333, 2.5, 2.0, 1.6667, 2.8333, 2.0, 2.0, 2.8333,
  3.3333, 1.0, 2.0, 3.1667, 2.5, 2.1667, 3.6667, 2.0, 2.5, 1.5, 2.3333, 1.6667,
  1.0, 1.1667, 2.3333, 3.6667, 3.0, 1.8333, 1.5, 2.0, 2.3333, 4.3333, 5.0, 2.0,
  1.0, 1.0, 2.6667, 2.0, 3.3333, 2.8333, 2.5, 1.0, 2.3333, 2.0, 3.8333, 3.0,
  3.0, 3.3333, 1.0, 2.0, 3.1667, 2.0, 2.5, 2.6667, 2.1667, 1.0, 2.0, 3.6667,
  1.0, 2.6667, 2.6667, 1.0, 2.6667, 1.0, 2.0, 3.6667, 4.0, 3.1667, 1.8333, 3.5,
  1.6667, 2.0, 1.5, 1.5, 2.0, 2.3333, 3.0, 1.8333, 1.6667, 3.0, 3.6667, 2.6667,
  2.1667, 1.1667, 1.6667, 3.0, 4.0, 2.5, 1.6667, 2.5, 1.5, 2.8333, 2.3333,
  1.6667, 1.0, 2.3333, 2.6667, 2.8333, 3.3333, 3.5, 1.0, 4.8333, 3.0, 3.3333,
  2.0, 2.0, 1.8333, 1.5, 1.0, 3.5, 3.6667, 1.0, 1.6667, 2.5, 2.8333, 2.6667,
  3.5, 3.0, 2.8333, 3.0, 1.0, 1.0, 3.3333, 3.0, 1.0, 3.0, 1.6667, 2.0, 1.3333,
  2.5, 2.3333, 1.6667, 2.6667, 1.0, 2.0, 4.3333, 2.1667, 3.1667, 1.0, 2.8333,
  1.1667, 3.0, 2.5, 2.3333, 1.0, 2.5, 4.1667, 3.0, 2.5, 2.5, 3.6667, 2.1667,
  1.8333, 5.0, 1.0, 4.0, 2.0, 4.1667, 2.8333, 1.0, 1.0, 2.5, 2.3333, 3.5, 1.0,
  2.8333, 2.5, 2.5, 2.3333, 2.3333, 2.3333, 2.6667, 2.3333, 3.0, 2.3333, 2.1667,
  1.0, 1.1667, 2.6667, 2.6667, 2.0, 2.3333, 3.8333, 3.0, 2.0, 2.6667, 2.6667,
  2.6667, 1.8333, 3.0, 2.0, 2.5, 3.8333, 2.6667, 2.3333, 1.6667, 2.0, 2.5, 2.5,
  1.0, 2.0, 4.3333, 4.0, 4.5, 2.0, 2.6667, 2.5, 2.1667, 3.0, 2.6667, 1.8333,
  3.5, 1.5, 1.6667, 1.0, 2.6667, 2.6667, 2.1667, 1.0, 2.3333, 3.5, 1.3333, 2.5,
  2.1667, 1.0, 2.5, 3.3333, 3.3333, 4.1667, 2.3333, 1.6667, 2.3333, 2.5, 1.8333,
  2.5, 1.6667, 3.0, 1.6667, 3.3333, 3.1667, 1.8333,
];

export const revenueEnhanceData = [
  2.0, 1.3333, 1.6667, 2.0, 4.0, 3.0, 2.6667, 4.0, 3.0, 1.6667, 1.0, 2.3333,
  2.0, 3.6667, 1.6667, 1.3333, 1.3333, 4.6667, 4.0, 3.3333, 1.0, 1.0, 4.0,
  1.6667, 1.0, 2.3333, 2.0, 1.0, 1.0, 2.3333, 4.0, 2.0, 1.0, 1.6667, 4.0, 1.0,
  2.3333, 4.0, 2.0, 1.3333, 3.6667, 1.6667, 4.0, 2.0, 2.6667, 2.0, 2.3333,
  2.3333, 3.6667, 1.3333, 1.0, 2.0, 1.0, 2.0, 1.0, 3.0, 1.0, 4.0, 3.0, 1.3333,
  1.6667, 1.0, 1.0, 1.6667, 4.0, 5.0, 1.3333, 1.0, 2.3333, 2.0, 2.3333, 5.0,
  3.0, 5.0, 1.0, 3.3333, 3.0, 4.0, 2.0, 4.6667, 2.0, 1.3333, 3.3333, 1.3333,
  3.0, 1.3333, 2.6667, 1.0, 2.0, 2.0, 2.0, 3.0, 1.3333, 2.0, 2.3333, 2.0,
  4.6667, 1.3333, 1.3333, 2.3333, 1.0, 1.0, 2.0, 1.6667, 4.0, 2.0, 4.0, 2.3333,
  1.6667, 2.0, 3.0, 1.0, 1.0, 2.0, 1.0, 4.0, 1.6667, 3.0, 4.0, 3.0, 3.3333, 1.0,
  4.3333, 3.3333, 3.3333, 1.0, 1.0, 5.0, 1.0, 4.0, 3.0, 4.0, 1.0, 3.3333, 2.0,
  3.3333, 1.0, 3.0, 5.0, 2.0, 3.3333, 4.0, 6.0, 1.0, 1.0, 1.0, 4.0, 1.3333,
  3.3333, 2.0, 4.0, 4.0, 2.6667, 3.0, 1.0, 2.0, 1.6667, 1.0, 2.6667, 1.0, 4.0,
  1.0, 1.0, 1.3333, 1.0, 2.6667, 1.0, 2.0, 2.3333, 2.0, 1.6667, 1.3333, 4.0,
  1.3333, 1.0, 5.0, 2.3333, 1.3333, 2.0, 3.6667, 3.3333, 2.0, 3.6667, 2.3333,
  4.0, 1.6667, 1.6667, 3.0, 2.3333, 1.6667, 1.6667, 2.0, 2.3333, 2.6667, 1.3333,
  1.6667, 1.0, 4.0, 1.3333, 3.0, 4.3333, 4.0, 1.6667, 1.6667, 1.0, 3.0, 4.0,
  1.0, 3.3333, 4.0, 2.6667, 1.0, 4.0, 4.0, 2.0, 2.0, 1.6667, 1.6667, 1.6667,
  2.6667, 3.3333, 1.3333, 4.0, 1.6667, 3.0, 1.0, 2.3333, 3.6667, 1.0, 4.0, 1.0,
  3.6667, 1.3333, 3.6667, 1.0, 1.3333, 4.0, 1.6667, 1.0, 3.0, 1.3333, 3.0, 2.0,
  1.6667, 2.0, 4.0, 1.3333, 1.0, 1.6667, 1.0, 2.0, 1.0, 1.0, 1.3333, 2.0,
  4.3333, 1.6667, 3.0, 4.0, 1.0, 1.0, 2.6667, 2.0, 1.3333, 1.6667, 1.3333, 2.0,
  2.0, 2.0, 3.3333, 2.0, 3.6667, 1.6667, 4.0, 3.6667, 2.0, 1.6667, 3.3333, 2.0,
  2.6667, 4.0, 1.0, 2.0, 1.3333, 3.0, 1.6667, 2.3333, 4.0, 1.6667, 3.0, 1.6667,
  2.0, 1.0, 2.6667, 1.0, 1.6667, 2.6667, 1.3333, 4.3333, 4.0, 1.6667, 1.3333,
  2.0, 1.0, 3.0, 3.3333, 2.6667, 1.3333, 1.0, 3.0, 1.0, 3.3333, 2.0, 3.0, 1.0,
  1.0, 2.0, 3.0, 1.3333, 2.0, 4.0, 3.3333, 1.6667, 4.0, 1.3333, 1.6667, 3.0,
  2.0, 2.0, 1.0, 1.0, 1.0, 1.0, 1.6667, 2.0, 1.0, 2.3333, 3.3333, 2.3333, 1.0,
  1.6667, 1.0, 1.0, 2.0, 1.0, 2.3333, 1.0, 1.0, 3.0, 1.3333, 3.6667, 2.3333,
  2.3333, 2.0, 4.0, 1.0, 1.0, 2.6667, 4.3333, 3.0, 3.6667, 3.3333, 2.3333, 2.0,
  1.3333, 3.0, 2.3333, 2.3333, 4.0, 1.6667, 1.0, 2.0, 4.0, 3.6667, 4.0, 2.0,
  1.0, 2.0, 4.3333, 3.6667, 1.6667, 1.0, 2.3333, 1.3333, 1.0, 2.3333, 1.6667,
  1.0, 4.0, 2.0, 4.0, 2.6667, 2.0, 2.3333, 2.0, 4.0, 4.0, 2.0, 2.6667, 2.0, 1.0,
  1.0, 2.0, 1.6667, 2.3333, 1.6667, 2.3333, 2.0, 3.6667, 1.6667, 1.0,
];

export const turnOverData = [
  1073924, 900000, 6500000, 3839360, 752837, 7442645, 700000, 524822, 370366,
  166699, 393467, 479920, 944449, 610775, 265766, 1884468, 1560625, 4276000,
  1000000, 222413, 1136133, 314999, 192454, 113443, 1576680, 2956984, 448954,
  175469, 708979, 740656, 495583, 550771, 1803065, 131617, 587014, 1000000,
  741457, 668890, 122136, 1874549, 1000000, 392166, 1552124, 500000, 1900744,
  3420324, 8314986, 1124201, 404050, 730302, 104588, 627803, 180273, 524847,
  228720, 1241307, 309456, 468364, 3111990, 3693830, 262522, 2018319, 900000,
  2831059, 2032642, 450000, 605872, 228217, 3293000, 3894488, 450000, 1038870,
  4686266, 553795, 2463111, 2000000, 238013, 5000000, 477699, 2000000, 1368078,
  124536, 1002586, 1202660, 1676433, 203828, 2244919, 3498310, 232064, 1000000,
  717214, 772635, 103552, 902759, 1515329, 2404309, 815643, 2050800, 5610963,
  4545760, 153676, 151865, 120115, 407280, 166317, 631580, 1000000, 200000,
  2340623, 305322, 6454534, 409721, 448828, 1600000, 295278, 405644, 401915,
  215055, 373054, 522566, 457111, 837799, 302027, 514262, 1308223, 244211,
  714314, 1213195, 724600, 987742, 500000, 367317, 747494, 1174801, 513092,
  874357, 199243, 439953, 1824978, 212130, 450105, 928772, 901252, 500299,
  492326, 317530, 500000, 256685, 699346, 1326458, 2420684, 312701, 469476,
  1350845, 517913, 291541, 386915, 272470, 6924765, 6684100, 362436, 5948159,
  513046, 1479734, 1281324, 1514054, 690715, 670362, 629994, 617005, 1006382,
  8402504, 871438, 325640, 755024, 1099723, 8068443, 1339819, 617651, 5000000,
  1852232, 122166, 795994, 538015, 477750, 1675637, 3859000, 4187809, 1315863,
  1500000, 400000, 4687352, 677312, 6113379, 1000000, 715335, 7021790, 935494,
  569721, 9794708, 1091807, 7981425, 266481, 277600, 4255971, 800000, 8043803,
  8036612, 1064441, 398994, 2208017, 2482269, 8470702, 2188641, 612058, 229932,
  492669, 2982987, 1803754, 1664353, 4698407, 743558, 1296338, 1068446, 1663439,
  320648, 2748111, 973883, 665676, 7321790, 6347953, 906012, 945240, 4082439,
  100543, 584614, 6000000, 1009439, 1168753, 4664132, 7000000, 1089421, 1500000,
  8339430, 403713, 6654879, 3412169, 500000, 271635, 284987, 1305135, 538889,
  353506, 2800000, 1345294, 9779003, 967087, 1894061, 8899726, 1200000, 380095,
  293610, 300000, 933919, 324830, 2615354, 9912409, 5295049, 5324027, 8709316,
  5777902, 8862916, 2005117, 529519, 2802827, 231845, 276142, 3430479, 1393170,
  1752760, 7288000, 761732, 2280212, 300000, 1245806, 194547, 1705550, 3951646,
  3402791, 1618396, 2131522, 559032, 5306805, 201421, 787161, 286077, 603697,
  617128, 667062, 5716745, 1557278, 1393143, 3186105, 1274254, 4971387, 715873,
  472213, 968667, 1424118, 6170928, 3299425, 876392, 383048, 443311, 346052,
  4216718, 7587978, 5023211, 1248730, 466737, 3764666, 336892, 1585339, 2838505,
  390217, 985672, 8291741, 2051902, 3224510, 2761488, 150834, 260517, 323319,
  762543, 115563, 573692, 719507, 881558, 627765, 449930, 4433613, 2671347,
  157283, 4020784, 491744, 552110, 1500000, 398708, 2053471, 1795591, 3723992,
  659046, 1085273, 221875, 225468, 255743, 122218, 5936946, 6060853, 4298068,
  1035050, 7768614, 624230, 2732121, 259696, 1337603, 6517798, 970037, 1139373,
  675550, 1154773, 688960, 2790386, 1162727, 1835463, 9113695, 1203719, 2897423,
  4637463, 2800000, 482343, 2026776, 1494955, 824401, 1009411, 5108280, 5384950,
  1026584, 849396, 325884, 2563995, 180000, 120566, 500000, 4094493, 2667492,
  8955136, 8000000, 3109128, 4277604, 365445, 285187, 287521, 294949, 184166,
  3241477, 470433, 1000000, 815110, 2192224, 528606,
];
