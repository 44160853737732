import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { Survey, results, FakeResults } from "./pages/survey/index";
import { LandingPage } from "./pages/main/index";

export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/quiz" element={<Survey />} />
        <Route path="/demo" element={<FakeResults />} />
        <Route path="*" element={<LandingPage />} />
      </Routes>
    </BrowserRouter>
  );
}
