import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";

import { Question } from "./index";
import "./questionHandler.css";

export const QuestionHandler = ({
  questions,
  nextQuestion,
  saveQuestionAnswer,
  isFinalQuestion,
}) => {
  const [isError, setIsError] = useState(false);

  const nextQuestionHandler = () => {
    setIsError(false);
    let foundError = false;

    questions.categoryQuestions.forEach((question) => {
      if (question.questionType !== "freeForm" && !question.questionAnswer) {
        foundError = true;
        setIsError(true);
      }
    });

    if (!foundError) {
      nextQuestion();
    }
  };

  return (
    <div className="question-container">
      <div className="category-name">{questions.categoryName}</div>
      {questions.categoryQuestions.map((question, questionIndex) => {
        return (
          <Question
            key={question.id}
            categoryQuestion={question}
            saveQuestionAnswer={saveQuestionAnswer}
            questionIndex={questionIndex}
            prevAnswer={questions.categoryQuestions[questionIndex - 1]}
          />
        );
      })}
      <div className="error">
        {isError ? "Please answer all the questions" : ""}
      </div>
      <Button variant="contained" onClick={() => nextQuestionHandler()}>
        {isFinalQuestion ? "Finish" : "Next"}
      </Button>
    </div>
  );
};
