import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "@mui/material/Button";
import { green } from "@mui/material/colors";
import CircularProgress from "@mui/material/CircularProgress";
import EastIcon from "@mui/icons-material/East";
import {
  managementData,
  resourceData,
  productServiceData,
  computerAidedData,
  integrationData,
  automationData,
  realtimeData,
  analyticsData,
  supplyChainData,
  turnOverData,
  revenueEnhanceData,
} from "./diagram.data";
import Plot from "react-plotly.js";

import "./diagram.css";

export const Diagram = ({ predictionData }) => {
  const [management, setManagement] = useState(1);
  const [resource, setResource] = useState(1);
  const [productService, setProductService] = useState(1);
  const [computerAided, setComputerAided] = useState(1);
  const [integration, setIntegration] = useState(1);
  const [automation, setAutomation] = useState(1);
  const [realtime, setRealtime] = useState(1);
  const [analytics, setAnalytics] = useState(1);
  const [supplyChain, setSupplyChain] = useState(1);
  const [prediction, setPrediction] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (predictionData.management === null) return;

    setManagement(predictionData.management);
    setResource(predictionData.resource);
    setProductService(predictionData.productService);
    setComputerAided(predictionData.computerAided);
    setIntegration(predictionData.integration);
    setAutomation(predictionData.automation);
    setRealtime(predictionData.realTime);
    setAnalytics(predictionData.analytics);
    setSupplyChain(predictionData.supplyChain);
  }, [predictionData]);

  const trace = [
    {
      type: "parcoords",
      line: {
        showscale: true,
        colorscale: "Jet",
        cmin: 100000,
        cmax: 10000000,
        color: turnOverData,
      },
      unselected: {
        line: {
          color: "black",
          opacity: 0.05,
        },
      },
      dimensions: [
        {
          range: [100000, 10000000],
          label: "Turnover",
          values: turnOverData,
          showTickLabels: false,
        },
        {
          range: [1, 5],
          label: "Transformation management",
          values: managementData,
        },
        {
          range: [1, 5],
          label: "Digitalization resource",
          values: resourceData,
        },
        {
          range: [1, 5],
          label: "Servitization capability",
          values: productServiceData,
        },
        {
          range: [1, 5],
          label: "*CADCM",
          values: computerAidedData,
        },
        {
          range: [1, 5],
          label: "Augmentation capability",
          values: integrationData,
        },
        {
          range: [1, 5],
          label: "Automation capability",
          values: automationData,
        },
        {
          range: [1, 5],
          label: "Real-time information",
          values: realtimeData,
        },
        {
          range: [1, 5],
          label: "Analytic capability",
          values: analyticsData,
        },
        {
          range: [1, 5],
          label: "Supply chain digitalization",
          values: supplyChainData,
        },
        {
          range: [1, 7],
          label: "*RED",
          values: revenueEnhanceData,
        },
      ],
    },
  ];

  const fetchPrediction = () => {
    setLoading(true);
    const fetchBody = {
      management,
      resource,
      productService,
      computerAided,
      integration,
      automation,
      realtime,
      analytics,
      supplyChain,
    };
    fetch("https://digitalroadmaps.eu//api/predict", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(fetchBody),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const predictionValueNumber = data.substring(1, data.length - 1);
        const predictionValueRounded =
          Math.round(predictionValueNumber * 100) / 100;
        setPrediction(predictionValueRounded);
        setLoading(false);
      });
  };

  const handleButtonUpdate = (prev, newVal) => {
    if (newVal > 5 || newVal < 1) return prev;
    return newVal;
  };

  return (
    <>
      <div className="diagram-container">
        <div
          className="result-text"
          style={{ display: "flex", flexFlow: "row" }}
        >
          <b>Interactive Digitalization Pathways modelling</b>
        </div>

        <p className="info-text" style={{ marginBottom: 5 }}>
          Interactive Digitalization Pathways modelling tool helps you plan
          Digitalization pathways.
        </p>
        <p className="info-text" style={{ marginBottom: 5 }}>
          You can interactively choose the level of turnover and capabilities
          levels in the interactive Digitalization Scenario modeling tool below.
          To set the level, click-and-drag the vertical lines representing
          turnover and capabilities to indicate approximately the current or
          desired level. The Digitalization Scenario modeling tool will reveal
          which pathways companies followed to achieve the desired level of
          revenue enhancement through digitalization. The tool is interactive,
          you can engage in unlimited number of iterations of Digitalization
          Pathways modelling.
        </p>

        <Plot
          data={trace}
          layout={{
            width: 1600,
            height: 900,
            // title:
            //   "Diagram for ataining ultimate knowledge about everything related A.I.",
            margin: {
              pad: 100,
            },
          }}
        />

        <p style={{ marginBottom: 50, display: "flex", flexFlow: "column" }}>
          <div style={{ display: "flex", flexFlow: "row" }}>
            <div>
              <strong style={{ marginRight: 5 }}>*RED </strong>
            </div>
            <div> - Revenue enhancement through digitalization</div>
          </div>
          <div style={{ display: "flex", flexFlow: "row" }}>
            <div>
              <strong style={{ marginRight: 5 }}>*CADCM </strong>
            </div>
            <div> - Computer-aided design and CNC machinery</div>
          </div>
        </p>

        <b>Note:</b>

        <p>
          Scales of Transformation management capabilities and Digitalization
          resource capabilities: 1-Absent, 2-Weak, 3-Average, 4-Strong, 5-Very
          strong. Scales of Servitization, Computer-aided design and CNC
          machinery, Augmentation, Automation, Real-time, Analytic, Supply chain
          digitalization: 1-2 Absent, 3- Planning/ Piloting; 4 - Used locally; 5
          - Used widely across organization. Powered by parallel coordinates
          visualization algorithm.
        </p>
      </div>

      <div className="divider" />

      <div className="diagram-container">
        <div
          className="result-text"
          style={{ display: "flex", flexFlow: "row" }}
        >
          <b>Interactive Digitalization Benefits modelling</b>
        </div>
        <p className="info-text">
          The Interactive Digitalization Benefits modeling tool helps you to
          predict revenue enhancement through digitalization due to the
          development of capabilities. The current levels reflect the data you
          have provided in the questionnaire. Click predict. Observe the level
          of revenue enhancement through digitalization. Change the levels of
          capabilities to the desired ones. Click predict. Observe changes in
          the level of revenue enhancement through digitalization. The revenue
          enhancement could also decrease while specific capabilities increase.
          Digitalization is costly eventually!
        </p>

        <div
          style={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="button-group">
            <div className="button-wrapper">
              <div>Transformation management capabilities</div>
              <div className="button-container">
                <Button
                  disabled={management === 1 ? true : false}
                  onClick={() =>
                    setManagement((prev) => handleButtonUpdate(prev, prev - 1))
                  }
                >
                  <RemoveIcon />
                </Button>
                <p>{management}</p>
                <Button
                  disabled={management === 5 ? true : false}
                  className="button-style"
                  size="small"
                  onClick={() =>
                    setManagement((prev) => handleButtonUpdate(prev, prev + 1))
                  }
                >
                  <AddIcon />
                </Button>
              </div>
            </div>

            <div className="button-wrapper">
              <div>Digitalization resource capabilities</div>
              <div className="button-container">
                <Button
                  disabled={resource === 1 ? true : false}
                  onClick={() =>
                    setResource((prev) => handleButtonUpdate(prev, prev - 1))
                  }
                >
                  <RemoveIcon />
                </Button>
                <p>{resource}</p>
                <Button
                  disabled={resource === 5 ? true : false}
                  className="button-style"
                  size="small"
                  onClick={() =>
                    setResource((prev) => handleButtonUpdate(prev, prev + 1))
                  }
                >
                  <AddIcon />
                </Button>
              </div>
            </div>

            <div className="button-wrapper">
              <div>Servitization capability</div>
              <div className="button-container">
                <Button
                  disabled={productService === 1 ? true : false}
                  onClick={() =>
                    setProductService((prev) =>
                      handleButtonUpdate(prev, prev - 1)
                    )
                  }
                >
                  <RemoveIcon />
                </Button>
                <p>{productService}</p>
                <Button
                  disabled={productService === 5 ? true : false}
                  className="button-style"
                  size="small"
                  onClick={() =>
                    setProductService((prev) =>
                      handleButtonUpdate(prev, prev + 1)
                    )
                  }
                >
                  <AddIcon />
                </Button>
              </div>
            </div>
          </div>

          <div className="button-group">
            <div className="button-wrapper">
              <div>Computer-aided design and CNC machinery</div>
              <div className="button-container">
                <Button
                  disabled={computerAided === 1 ? true : false}
                  onClick={() =>
                    setComputerAided((prev) =>
                      handleButtonUpdate(prev, prev - 1)
                    )
                  }
                >
                  <RemoveIcon />
                </Button>
                <p>{computerAided}</p>
                <Button
                  disabled={computerAided === 5 ? true : false}
                  className="button-style"
                  size="small"
                  onClick={() =>
                    setComputerAided((prev) =>
                      handleButtonUpdate(prev, prev + 1)
                    )
                  }
                >
                  <AddIcon />
                </Button>
              </div>
            </div>

            <div className="button-wrapper">
              <div>Augmentation capability</div>
              <div className="button-container">
                <Button
                  disabled={integration === 1 ? true : false}
                  onClick={() =>
                    setIntegration((prev) => handleButtonUpdate(prev, prev - 1))
                  }
                >
                  <RemoveIcon />
                </Button>
                <p>{integration}</p>
                <Button
                  disabled={integration === 5 ? true : false}
                  className="button-style"
                  size="small"
                  onClick={() =>
                    setIntegration((prev) => handleButtonUpdate(prev, prev + 1))
                  }
                >
                  <AddIcon />
                </Button>
              </div>
            </div>

            <div className="button-wrapper">
              <div>Automation capability</div>
              <div className="button-container">
                <Button
                  disabled={automation === 1 ? true : false}
                  onClick={() =>
                    setAutomation((prev) => handleButtonUpdate(prev, prev - 1))
                  }
                >
                  <RemoveIcon />
                </Button>
                <p>{automation}</p>
                <Button
                  disabled={automation === 5 ? true : false}
                  className="button-style"
                  size="small"
                  onClick={() =>
                    setAutomation((prev) => handleButtonUpdate(prev, prev + 1))
                  }
                >
                  <AddIcon />
                </Button>
              </div>
            </div>
          </div>

          <div className="button-group">
            <div className="button-wrapper">
              <div>Real-time information capability</div>
              <div className="button-container">
                <Button
                  disabled={realtime === 1 ? true : false}
                  onClick={() =>
                    setRealtime((prev) => handleButtonUpdate(prev, prev - 1))
                  }
                >
                  <RemoveIcon />
                </Button>
                <p>{realtime}</p>
                <Button
                  disabled={realtime === 5 ? true : false}
                  className="button-style"
                  size="small"
                  onClick={() =>
                    setRealtime((prev) => handleButtonUpdate(prev, prev + 1))
                  }
                >
                  <AddIcon />
                </Button>
              </div>
            </div>

            <div className="button-wrapper">
              <div>Analytic capability</div>
              <div className="button-container">
                <Button
                  disabled={analytics === 1 ? true : false}
                  onClick={() =>
                    setAnalytics((prev) => handleButtonUpdate(prev, prev - 1))
                  }
                >
                  <RemoveIcon />
                </Button>
                <p>{analytics}</p>
                <Button
                  disabled={analytics === 5 ? true : false}
                  className="button-style"
                  size="small"
                  onClick={() =>
                    setAnalytics((prev) => handleButtonUpdate(prev, prev + 1))
                  }
                >
                  <AddIcon />
                </Button>
              </div>
            </div>

            <div className="button-wrapper">
              <div>Supply chain digitalization capability</div>
              <div className="button-container">
                <Button
                  disabled={supplyChain === 1 ? true : false}
                  onClick={() =>
                    setSupplyChain((prev) => handleButtonUpdate(prev, prev - 1))
                  }
                >
                  <RemoveIcon />
                </Button>
                <p>{supplyChain}</p>
                <Button
                  disabled={supplyChain === 5 ? true : false}
                  className="button-style"
                  size="small"
                  onClick={() =>
                    setSupplyChain((prev) => handleButtonUpdate(prev, prev + 1))
                  }
                >
                  <AddIcon />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="prediction-button-grp">
          <div className="button-container">
            <Button
              disabled={loading}
              sx={{
                bgcolor: green[500],
                "&:hover": {
                  bgcolor: green[700],
                },
              }}
              style={{
                width: 100,
                height: 49,
                fontSize: 18,
                marginRight: 30,
              }}
              variant="contained"
              onClick={() => fetchPrediction()}
            >
              {loading && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
              Predict
            </Button>
            <EastIcon style={{ marginRight: 30 }} />
            <div className="revenue-enhance">
              <p className="revenue-text">
                Revenue enhancement through digitalization
              </p>
              <p className="revenue-text">{prediction}</p>
            </div>
          </div>
        </div>
        <b>Note:</b>
        <p>
          Revenue enhancement though digitalization (i.e., direct revenue
          enhancement, indirect revenue enhancement, cost savings from
          digitalization) – 1 – Very low; 2 – Low, 3 - Below average, Low; 4 –
          average; 5 – High.
        </p>
        <p>
          Transformation management and Digitalization resource: 1-Absent,
          2-Weak, 3-Average, 4-Strong, 5-Very strong. Servitization,
          Augmentation, Automation, Real-time, Analytic, Supply chain
          digitalization: 1-2 Absent, 3- Planning/ Piloting; 4 - Used locally; 5
          - Used widely across organization.
        </p>
      </div>
    </>
  );
};
