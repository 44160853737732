import * as React from "react";
import "./progress.css";

export const Progress = ({ progressValue }) => {
  return (
    <div className="progress-container">
      <div className="progress">
        <div
          className="progress-bar"
          style={{
            width: `${progressValue}%`,
          }}
        ></div>
        {/* <div className="progress-text">{progressValue}%</div> */}
      </div>
    </div>
  );
};
