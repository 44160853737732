import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import HomeIcon from "@mui/icons-material/Home";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";

import "./fake_result.css";
import { Diagram } from "./components/diagram/diagram";

export const FakeResults = ({ questions }) => {
  const tempObject = {
    date: "2023-02-18",
    q0: "Lithuania",
    q1: 32,
    q4: 2,
    q5: 2,
    q6: 2,
    q7_1: 2,
    q7_2: 2,
    q7_3: 2,
    q8_1: 2,
    q8_1_1: 2001,
    q8_2: 2,
    q8_2_1: 2001,
    q8_3: 2,
    q9_1: 2,
    q9_2: 2,
    q9_3: 2,
    q10_1: 2,
    q10_2: 2,
    q10_3: 2,
    q11_1: 2,
    q11_2: 2,
    q11_3: 2,
    q12_1: 2,
    q12_2: 2,
    q12_3: 2,
    q13_1: 2,
    q13_2: 2,
    q13_3: 2,
    q15_1: 2,
    q15_1_1: 2001,
    q15_2: 2,
    q15_2_1: 2001,
    q15_3: 2,
    q15_3_1: 2001,
    q16_1: 2,
    q16_1_1: 2001,
    q16_2: 2,
    q16_2_1: 2001,
    q16_3: 2,
    q16_3_1: 2001,
    q17_1: 2,
    q17_2: 2,
    q17_3: 2,
    q17_3_1: 2001,
    q18_1: 2,
    q18_1_1: 2001,
    q18_2: 2,
    q18_2_1: 2001,
    q18_3: 2,
    q18_3_1: 2001,
    q19_1: 2,
    q19_1_1: 2001,
    q19_2: 2,
    q19_2_1: 2001,
    q19_3: 2,
    q19_3_1: 2001,
    q20_1: 2,
    q20_1_1: 2001,
    q20_2: 2,
    q20_2_1: 2001,
    q20_3: 2,
    q20_3_1: 2001,
    q21_1: 5,
    q21_1_1: 2001,
    q21_2: 3,
    q21_2_1: 2001,
    q21_3: 3,
    q21_3_1: 2001,
    q22_1: 4,
    q22_1_1: 2001,
    q22_2: 2,
    q22_2_1: 2001,
    q22_3: 6,
    q22_3_1: 2001,
    q23_1: 3,
    q23_1_1: 2001,
    q23_2: 5,
    q23_2_1: 2001,
    q23_3: 3,
    q23_3_1: 2001,
    q24_1: 3,
    q24_1_1: 2001,
    q24_2: 4,
    q24_2_1: 2001,
    q24_3: 3,
    q24_3_1: 2001,
    q25_1: 3,
    q25_1_1: 2001,
    q25_2: 3,
    q25_2_1: 2001,
    q25_3: 4,
    q25_3_1: 2001,
    q26_1: 5,
    q26_1_1: 2001,
    q26_2: 3,
    q26_2_1: 2001,
    q26_3: 3,
    q26_3_1: 2001,
    q27_1: 3,
    q27_1_1: 2001,
    q27_2: 4,
    q27_2_1: 2001,
    q27_3: 3,
    q27_3_1: 2001,
    q28_1: 3,
    q28_1_1: 2001,
    q28_2: 3,
    q28_2_1: 2001,
    q28_3: 4,
    q28_3_1: 2001,
    q29_1: 4,
    q29_2: 4,
    q29_3: 4,
    q30_1: 3,
    q30_2: 3,
    q30_3: 3,
    q31_1: 5,
    q31_2: 4,
    q31_3: 3,
    q31_4: 3,
    q32_1: 2,
    q32_2: 3,
    q32_3: 4,
    q32_4: 5,
    q32_5: 4,
    q32_6: 3,
    q33_1: 1992,
    q33_2: 3,
    q33_3: 15,
    q33_4: 50,
    q33_5: 20,
    q33_6: 15,
    q33_7: 2001,
  };

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [donwloadURL, setDownloadURL] = useState("");
  const [predictionData, setPredictionData] = useState({
    analytics: null,
    automation: null,
    computerAided: null,
    integration: null,
    management: null,
    productService: null,
    realTime: null,
    resource: null,
    supplyChain: null,
  });
  const timer = React.useRef();

  const buttonSx = {
    ...(success && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };
  useEffect(() => {
    setSuccess(false);
    setLoading(true);

    fetch("https://digitalroadmaps.eu/api/generateFileURL", {
      method: "POST",
      mode: "cors",
      body: JSON.stringify(tempObject),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPredictionData({
          analytics: data.Analytics,
          automation: data.Automation,
          computerAided: data.Computer_aided,
          integration: data.Integration,
          management: data.Management,
          productService: data.Product_service,
          realTime: data.Real_time,
          resource: data.Resource,
          supplyChain: data.Supply_chain,
        });
        if (data.url) {
          setSuccess(true);
          setLoading(false);
          setDownloadURL(`https://digitalroadmaps.eu/${data.url}`);
        }
      })
      .catch(() => {
        setSuccess(false);
        setLoading(false);
      });
  }, []);

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
    }
  };

  return (
    <div className="result-container">
      <Link to={`/`} style={{ alignSelf: "start", margin: 17 }}>
        <HomeIcon sx={{ fontSize: 40, color: "grey" }} />
      </Link>
      <div className="diagram-container">
        <div className="result-text">Results</div>
        <p className="info-text">
          We have prepared your Digitalization Roadmap based on the data you
          have provided! Click below to download your Digitalization roadmap.
        </p>
        <Box sx={{ display: "flex", alignItems: "center", marginBottom: 5 }}>
          <Box sx={{ m: 1, position: "relative" }}>
            <Button
              variant="contained"
              sx={buttonSx}
              disabled={loading}
              onClick={handleButtonClick}
              href={donwloadURL}
            >
              Download PDF
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Box>
      </div>

      <div className="divider" />

      <Diagram predictionData={predictionData} />
    </div>
  );
};
